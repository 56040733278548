import AOS from "aos";
import React, { useEffect, useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { Modal, Button } from "antd";

import aboutImg from "../../../../../assets/images/community/about-section.png";
import communityLogo from "../../../../../assets/images/community/community partners/logo1.png";
import contributeSectionImage from "../../../../../assets/images/community/contribute-section.png";
import globe from "../../../../../assets/images/community/event-section.png";
import joinCommunity1 from "../../../../../assets/images/community/join-community-1.png";
import joinCommunity2 from "../../../../../assets/images/community/join-community-2.png";
import joinCommunity3 from "../../../../../assets/images/community/join-community-3.png";
import planeIcon from "../../../../../assets/images/community/plane-departure.png";
import quotesIcon from "../../../../../assets/images/community/quotes.png";
import redirectIcon from "../../../../../assets/images/community/redirect-icon.png";
import volunteerSectionImage from "../../../../../assets/images/community/volunteer-section.png";
import { Story } from "./assets";
// import timerIcon from "../../../../../assets/images/community/timer.png";
// import learnIcon from "../../../../../assets/images/community/LearnCommunity.png";
// import starIcon from "../../../../../assets/images/community/stars.png";
import footerImg from "../../../../../assets/images/community/footer.png";

import { FaFilePen } from "react-icons/fa6";
import { LiaConnectdevelop } from "react-icons/lia";
import { MdImageSearch } from "react-icons/md";
import galeryimg2 from "../../../../../assets/img/Rectangle 10.png";
import galeryimg3 from "../../../../../assets/img/Rectangle 11.png";
import galeryimg4 from "../../../../../assets/img/Rectangle 12.png";
import galeryimg5 from "../../../../../assets/img/Rectangle 2.png";
import galeryimg6 from "../../../../../assets/img/Rectangle 3 (1).png";
import galeryimg9 from "../../../../../assets/img/Rectangle 3.png";
import galeryimg7 from "../../../../../assets/img/Rectangle 4.png";
import galeryimg8 from "../../../../../assets/img/Rectangle 7.png";
import galeryimg1 from "../../../../../assets/img/Rectangle 8.png";
import galeryimg10 from "../../../../../assets/img/Rectangle 9.png";


export const HeroSection = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className='row mx-0'>
        <div className='col-12 px-0'>
          <section className='hero-section'>
            <div className='hero-main gap-lg-2 gap-0'>
              <h1>
                Welcome to <br />
                Datacode Community
              </h1>
              <p className='col-lg-5 col-sm-6 col-11 '>
                One of the marvelous things about community is that it enables
                us to welcome and help people in a way we couldn't as
                individuals
              </p>
              <div className=''>
                <button className='btn btn-sm'>Browse Community</button>
              </div>
            </div>
            <p>Dedicated Space for Enaging</p>
          </section>
        </div>
      </div>
    </>
  );
};

export const Counter = () => {
  return (
    <>
      <div className='row mx-0 justify-content-center'>
        <div className='col-11 px-0'>
          <section className='counter-section  padding-y'>
            <div className='row mx-0 '>
              <div className=' col-lg-4 text-uppercase' data-aos='zoom-in'>
                <h1>
                  20<span className='pl-3'>K</span>
                </h1>
                <p>Community Members</p>
              </div>
              <div className='col-lg-4 text-uppercase ' data-aos='zoom-in'>
                <h1>
                  80<span className='pl-1'>+</span>
                </h1>
                <p>Community Contributors</p>
              </div>
              <div className=' col-lg-4 text-uppercase' data-aos='zoom-in'>
                <h1>
                  20<span className='pl-1'>+</span>
                </h1>
                <p>Community Mentors</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export const Quote = () => {
  return (
    <>
      <div className="row mx-0 justify-content-center">
        <div className="col-11 px-0">
          <div className='row mx-0'>
            <div className='col-12'>
              <section className='quote-section ' data-aos='fade-up'>
                <div className='quote'>
                  <p className='quote-section-P lh-base'>
                    "Empowering minds, fostering connections. Welcome to a community
                    where collaboration sparks innovation, learning knows no
                    boundaries, and together, we shape a future fueled by knowledge
                    and shared passions."
                  </p>
                  <div></div>
                </div>
                <div className='video'>
                  <iframe
                    src='https://www.youtube.com/embed/myFNpEsqrUw?si=hfqECm2HkiUbN034'
                    title='YouTube video player'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowFullScreen=''
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const About = () => {
  return (
    <>
      <div className='row mx-0'>
        <div className='col-12 px-0'>
          <section className='about-section'>
            <div className='col-lg-8 mx-auto '>
              <h1 className='heading'>About Our Community</h1>
              <p className='py-4 mx-sm-0'>
                Our community brings together tech enthusiasts, developers, and
                data experts in a collaborative space. We focus on learning,
                sharing ideas, and tackling challenges together. Join us to
                connect, grow, and shape the future of technology.
              </p>
            </div>
            <div className='row mx-0 about-container'>
              <div className='col-lg-6  px-0 about-left'>
                <img src={aboutImg} alt='aboutImg' />
              </div>
              <div className='col-lg-6  about-right gap-3  px-lg-5'>
                <div className='d-flex flex-column gap-lg-2 gap-1'>
                  <div className='about-card gap-2 ' data-aos='fade-left'>
                    <div>
                      <div className='d-flex align-items-center gap-1'>
                        <FaRegCheckCircle
                          className='fs-4 rounded-circle'
                          style={{ color: "white", background: "blue" }}
                        />
                        <h3 className='text-uppercase  mb-0'>Our Vision</h3>
                      </div>
                      <p className=' pt-2 mb-0'>
                        To provide an accessible platform where beginners can
                        find engaging, high-quality content to unlock their
                        potential and succeed in tech.
                      </p>
                    </div>
                  </div>
                  <div className='about-card gap-2 p-3 ' data-aos='fade-left'>
                    <div>
                      <div className='d-flex align-items-center gap-1'>
                        <FaRegCheckCircle
                          className='fs-4 rounded-circle'
                          style={{ color: "white", background: "blue" }}
                        />
                        <h3 className='text-uppercase  mb-0'>Our Culture</h3>
                      </div>
                      <p className=' pt-2 mb-0'>
                        At DataCode, we put people first. Our culture is built
                        on collaboration, support, and shared growth, where
                        everyone’s ideas matter as we work together to shape the
                        future.
                      </p>
                    </div>
                  </div>
                  <div className='about-card gap-2 p-3' data-aos='fade-left'>
                    <div>
                      <div className='d-flex align-items-center gap-1'>
                        <FaRegCheckCircle
                          className='fs-4 rounded-circle'
                          style={{ color: "white", background: "blue" }}
                        />
                        <h3 className='text-uppercase  mb-0'>Our Value</h3>
                      </div>
                      <p className=' pt-2 mb-0'>
                        We value collaboration, integrity, and growth. We
                        support each other, embrace innovation, and strive for
                        excellence to make a positive impact together.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export const JoinCommunity = () => {
  return (
    <>
      <div className='row mx-0 justify-content-center join-community-section'>
        <div className='col-11 px-0'>
          <section className='padding-x'>
            <div className='row  justify-content-center  align-items-center gap-4 gap-lg-0'>
              <div className='col-lg-6 col-12 d-flex  gap-lg-1 position-relative align-items-center justify-content-center'>
                {/* <img
                  alt="joinCommunityIcon"
                  src={joinCommunityIcon}
                  className="icon "
                  data-aos="fade-down"
                /> */}
                <img
                  src={joinCommunity1}
                  data-aos='fade-down'
                  alt='joinCommunityIcon'
                />
                <img
                  alt='joinCommunityIcon'
                  src={joinCommunity2}
                  className='position-relative img-middle'
                  data-aos='fade-up'
                />
                <img
                  src={joinCommunity3}
                  data-aos='fade-down'
                  alt='joinCommunityIcon'
                />
              </div>
              <div className='col-lg-6 col-12 align-items-center text-lg-left'>
                <h1 className='heading'>
                  Enjoy your time in the <br />
                  Community
                </h1>
                <p className='py-3 '>
                  {/* This is CTA for joining community. <br />  */}
                  Ready to take your learning to the next level? Become a part
                  of our vibrant community, where you'll connect with
                  like-minded individuals, share ideas, and grow together.
                  Whether you're just starting out or looking to expand your
                  skills, here you'll find support, resources, and endless
                  opportunities to thrive. Join us today and be a part of
                  something exciting!
                </p>
                <div className=''>
                  <a
                    href='https://chat.whatsapp.com/CEGmMHdDZk21kiPIgAocAp'
                    target='_blank'
                  >
                    <button className='btn  btn-lg '>Join Our Community</button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export const Events = () => {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getOrganiserEvents({ page: "all", limit: 4, state: "all" }));
  //   debugger;
  // }, []);
  return (
    <>
      <div className="row mx-0 justify-content-center">
        <div className="col-11 px-0">
          <div className='row mx-0'>
            <div className='col-12 '>
              <section className='text-lg-left event-section pl-lg-4'>
                <h1 className=' col-lg-5 text-capitalize fw-normal text-lg-left pl-lg-5'>
                  Join us for the Events <br /> and meetups
                </h1>
                <div className='row mx-0 gap-4 gap-lg-0'>
                  <div className='col-lg-5 pt-4 pl-lg-5 text-left'>
                    <div className='gap-1 d-flex flex-column'>
                      <div className='card card-section' data-aos='fade-right'>
                        <div className='card-body '>
                          <h6 className=' font-Montserrat'>Wittyhacks </h6>
                          <p className=' font-Montserrat'>
                            Wittyhacks has been a platform where technology leaders
                            and the brightest minds come together to collaborate on
                            building tools that solve real problems.
                          </p>
                          <a
                            href='https://wittyhacks.in/'
                            target='_blank'
                            className='event-link'
                          >
                            Join Event
                            <img src={redirectIcon} alt='' />
                          </a>
                        </div>
                      </div>
                      <div className='card card-section' data-aos='fade-right'>
                        <div className='card-body'>
                          <h6 className='fs-4 font-Montserrat'>
                            React Developers Meetup Indore
                          </h6>
                          <p className=' font-Montserrat'>
                            React Indore Developers Meetup! Whether you're a
                            seasoned React developer or just getting started, this
                            is the perfect opportunity to learn, network, and grow
                            within the React community.
                          </p>
                          <a
                            href='https://www.reactindore.com/events/667454ae6674d31418fd2763'
                            target='_blank'
                            className='event-link'
                          >
                            Join Event
                            <img src={redirectIcon} alt='' />{" "}
                          </a>
                        </div>
                      </div>
                      <div className='card  card-section' data-aos='fade-right'>
                        <div className='card-body'>
                          <h6 className='fs-4 font-Montserrat'>
                            AI Copilot Summit Indore
                          </h6>
                          <p className=' font-Montserrat'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Viverra nunc ant, nullam lobortis enim. Faucibus amet
                            etiam tincidunt rhoncus, ullamcorper velit.
                          </p>
                          <a href='/' className='event-link'>
                            Join Event
                            <img src={redirectIcon} alt='' />{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='pt-4'>
                      {" "}
                      <button className='btn-primary text-center px-lg-5 py-lg-3'>
                        See All Events
                      </button>
                    </div>
                  </div>
                  <div className='col-lg-7 text-lg-right pr-lg-3  px-0 event-right'>
                    <img src={globe} data-aos='zoom-in' alt='globe' className='' />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const VolunteerSection = () => {
  return (
    <>
      <div className='row mx-0 justify-content-center volunteer-section'>
        <div className='col-11 px-0'>
          <section className='px-lg-4'>
            <div className='row mx-0' data-aos='fade-up'>
              <div className='col-lg-6 col-12 d-flex flex-column gap-0 pl-lg-5  text-lg-left  padding-y'>
                <h1 className='heading'>
                  Join us in making a <br /> difference!
                </h1>
                <p className=' py-4 text-justify'>
                  Become a valued volunteer and contribute your skills to our
                  thriving community. Together, let's shape the future of our
                  shared journey.
                </p>
                <div>
                  <a
                    href='https://chat.whatsapp.com/CEGmMHdDZk21kiPIgAocAp'
                    target='_blank'
                  >
                    <button className='btn-secondary'>
                      Volunteer with Datacode
                    </button>
                  </a>
                </div>
              </div>
              <div className='col-lg-6 col-12 px-0 text-lg-right  d-md-block py-5 pr-lg-4'>
                <img src={volunteerSectionImage} alt='' />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export const ContributorSection = () => {
  const contributorImages = [
    {
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1731946937/jiu8hatj06rgbx70qlky.png",
      name: "Poorti Jain",
      title: "React Developer"
    },
    {
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1731947415/xetdwsfrxstbwbpagg7h.png",
      name: "Divya Attarde",
      title: "React Developer"
    },
    // {
    //   imageUrl:
    //     "http://res.cloudinary.com/datacode/image/upload/v1731947593/d81ufdxokiu5rgdsrhwe.png",
    //     name:"Akshay Mandliya",
    //   title:"React Developer"
    // },
    {
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1732198750/a6p41uy2fghxtlvphzhh.png",
      name: "Smriti Jain",
      title: "React Developer"
    },
    {
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1731999476/xwlgbzgodgb9tjm513xx.png",
      name: "Aryan Karma",
      title: "React Developer"
    },
    {
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1732264312/a5tjhmdl28xv8mizfbb2.png",
      name: "Sheetal Chouhan",
      title: "React Developer"
    },
    // {
    //   imageUrl:
    //     "http://res.cloudinary.com/datacode/image/upload/v1731946937/jiu8hatj06rgbx70qlky.png",
    // },
  ];
  return (
    <>
      <div className="row mx-0 justify-content-center">
        <div className="col-11">
          <div className='row mx-0'>
            <div className='col-12 px-0'>
              <section className='contributor-section'>
                <h1 className=''>Our Top Contributors</h1>
                <div className='line'></div>
                <div className='d-flex flex-wrap text-center align-items-center justify-content-center contributor-container px-5'>
                  {contributorImages.map((image, index) => (
                    <div className='' key={index}>
                      <img
                        alt=''
                        src={image.imageUrl}
                        className='rounded-circle border contributor-img'
                      />
                      <div className="pt-3">
                        <h5 className="text-center">{image.name}</h5>
                        <p className="text-center">{image.title}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ContributeSection = () => {
  return (
    <>
      <div className='row mx-0'>
        <div className='col-12 px-0'>
          <section className='contribute-section padding-x'>
            <div className='row mx-0 align-items-center padding-x'>
              <div className='col-md-7 col-12  text-center padding-y'>
                <img
                  src={contributeSectionImage}
                  data-aos='flip-down'
                  alt=''
                  className='ml-lg-4'
                />
              </div>
              <div className='col-md-5 col-12 px-0 text-md-left text-center py-5'>
                <div className='position-relative  justify-content-center align-items-center'>
                  <img
                    src={planeIcon}
                    className='position-absolute  text-center pl-5 fa-rotate-18'
                    alt=''
                    style={{ width: "150px", paddingBottom: "-50px" }}
                  />
                  <h1 className=''>Elevate your impact!</h1>
                </div>
                <p className='my-md-4 my-4 py-lg-2'>
                  Become a contributor and shape the narrative of our vibrant
                  community. Your unique talents and insights will fuel the
                  collective growth and innovation we strive for. Join us on
                  this exciting journey!
                </p>
                <div>
                  <a
                    href='https://chat.whatsapp.com/CEGmMHdDZk21kiPIgAocAp'
                    target='_blank'
                  >
                    <button className='btn btn-lg'>
                      Contribute to Datacode
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export const StorySection = () => {
  return (
    <>
      <div className='row mx-0 justify-content-center'>
        <div className='col-11 px-0'>
          <section className='text-start story-section'>
            <div className='row mx-0   justify-content-center'>
              <div
                className='col-lg-6 col-12 story-left text-lg-left'
                data-aos='fade-right'
              >
                <div className='d-flex px-0 gap-0'>
                  <h1 className='font-Montserrat px-0'>
                    Stories from the Community
                  </h1>
                  <img src={quotesIcon} alt='' className='px-0' />
                </div>
                <p className='mx-0'>
                  Our learners benefit from our rich repository of courses and
                  practice problems every day
                </p>
              </div>
              <div className='col-lg-6 col-12 story-right'>
                <div className='story-container'>
                  <Story />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export const TeamSection = () => {
  const teamImages = [
    {
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1731947415/xetdwsfrxstbwbpagg7h.png",
    },
    {
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1731947593/d81ufdxokiu5rgdsrhwe.png",
    },
    {
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1732198750/a6p41uy2fghxtlvphzhh.png",
    },
    {
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1731946937/jiu8hatj06rgbx70qlky.png",
    },
    {
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1731999476/xwlgbzgodgb9tjm513xx.png",
    },
    {
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1732264312/a5tjhmdl28xv8mizfbb2.png",
    },
  ];
  return (
    <>
      <div className='row mx-0 justify-content-center team-section'>
        <div className='col-11'>
          <section className=' padding-y'>
            <div
              className='row mx-0 align-items-center gap-4'
              data-aos='fade-up'
            >
              <div className='col-lg-6 col-12 px-0 team-left'>
                {teamImages.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image.imageUrl}
                      className={`team-img ${index === 2 ? "rounded-pill" : " "
                        } ${index === 3 ? "rounded-pill" : " "}`}
                      alt=''
                    />
                  </div>
                ))}
              </div>
              <div className='col-lg-5 col-12 px-0 text-lg-left '>
                <h1 className=''>Meet Our Team</h1>
                <h3 className='lh-base pt-1'>
                  Meet the people of Datacode <br /> Community
                </h3>
                <p className='py-lg-4 '>
                  At Datacode, we believe in the power of collaboration and the
                  strength that comes from diverse perspectives. Get to know the
                  talented individuals who drive our success and bring unique
                  expertise to the table.
                </p>
                <div className=''>
                  <button className=' btn-secondary btn-lg '>Learn More</button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export const CommunityPartnerSection = () => {
  return (
    <>
      <div className="row mx-0 justify-content-center my-lg-5">
        <div className="col-11 ">
          <h3 className='community-heading text-center'>Our Community Partners</h3>
          <div className="row mx-0 justify-content-center my-3">
            <div className="col-12 col-lg-3 col-md-3">
              <section className='community-partner-section padding-x'>

                <div
                  className='community-partner-container d-flex gap-md-4 gap-5 justify-content-md-evenly justify-content-center flex-wrap'
                  data-aos='fade-down'
                >
                  <img src={`http://res.cloudinary.com/datacode/image/upload/v1732277099/zs4otkj1yrq9rozg1xqo.png`} alt='' />
                  {/* <img src={communityLogo} alt='' />
          <img src={communityLogo} alt='' />
          <img src={communityLogo} alt='' />
          <img src={communityLogo} alt='' /> */}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const GallerySection = () => {
  return (
    <>
      <div className='row mx-0 gallery-section justify-content-center'>
        <div className='col-11'>
          <section className='px-lg-4 padding-y'>
            <div
              className='row mx-lg-0 px-lg-2 text-center py-1 gallery-section'
              data-aos='fade-up'
            >
              <div
                className='col-lg-6 col-12 align-items-center justify-content-center'
                data-aos='zoom-in'
              >
                <div className='image-grid d-flex py-4 align-items-center justify-content-center'>
                  <div className='d-flex flex-column ' style={{ gap: "8px" }}>
                    <img
                      src={
                        "http://res.cloudinary.com/datacode/image/upload/v1731954750/pt6bzhfbyr9wp8p5fwaf.jpg"
                      }
                      alt='image1'
                    />
                    <img src={galeryimg9} alt='image8' />
                    <img src={galeryimg8} alt='image8' />
                  </div>
                  <div className='d-flex flex-column ' style={{ gap: "8px" }}>
                    <img src={galeryimg1} alt='image2' />
                    <img
                      src={
                        "http://res.cloudinary.com/datacode/image/upload/v1731954209/w0mmjvehpa9bvuwejqeh.jpg"
                      }
                      alt='image5'
                    />
                    <img
                      src={
                        "http://res.cloudinary.com/datacode/image/upload/v1731954494/toeptlieb3mddwttwuxl.jpg"
                      }
                      alt='image3'
                    />
                    <img src={galeryimg2} alt='image2' />
                  </div>
                  <div className='d-flex flex-column ' style={{ gap: "8px" }}>
                    <img src={galeryimg6} alt='image6' />
                    <img
                      src={
                        "http://res.cloudinary.com/datacode/image/upload/v1731956174/y3jachvrr3h5jceo3mqu.jpg"
                      }
                      alt='image4'
                    />
                    <img src={galeryimg7} alt='image7' />
                  </div>
                </div>
              </div>
              <div
                className='col-lg-6 col-12 pt-lg-5 align-items-center justify-content-center'
                data-aos='zoom-in'
              >
                <div className='text-center pt-lg-5 py-3'>
                  <h3 className=''>
                    Glimpses from our <br /> community
                  </h3>
                  <p className='py-lg-4'>
                    Explore the lively moments that define our community – from
                    virtual meetups to collaborative projects. Get a sneak peek
                    into the vibrant energy that fuels our shared journey.
                  </p>
                  <div>
                    <a
                      href='https://chat.whatsapp.com/CEGmMHdDZk21kiPIgAocAp'
                      target='_blank'
                    >
                      <button className='btn btn-lg'>Join our Events</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export const CommunityProgramSection = () => {
  return (
    <>
      <div className='row mx-0'>
        <div className='col-12 px-0'>
          <section className='community-program-section' data-aos='fade-up'>
            <h1 className=' font-Montserrat pb-lg-5'>Our Community Programs</h1>
            <div className='program-container'>
              <h1 className='text-white  text-center'>
                Join us in Datacode Student Learning Clubs
              </h1>
              <p className='text-white my-md-5 py-2 text-center px-lg-5'>
                Embark on an exciting journey with Datacode Student Learning
                Club! Whether you're eager to enhance your skills or ready to
                lead and inspire, join our community. Connect with like-minded
                learners, explore projects, and contribute to the future of
                coding education. Together, let's shape the world of Computer
                Science!
              </p>
              {/* card container */}
              <div className='row mx-0 justify-content-center gap-lg-3 gap-1'>
                {/* card 1 */}
                <div
                  className='col-lg-3 col-md-4 col-12  card'
                  data-aos='fade-down'
                >
                  <div className=' pb-2 d-flex flex-row justify-content-between align-items-center align-content-center'>
                    <h5>Unleash Your Creativity</h5>
                    <span className='icon'>
                      <FaFilePen />
                    </span>
                  </div>
                  <p className=' text-justify'>
                    Explore innovative learning methods, experiential projects,
                    and out-of-the-box activities.
                  </p>
                </div>
                {/* card 2 */}
                <div
                  className='col-lg-3 col-md-4 col-12  card'
                  data-aos='fade-down'
                >
                  <div className=' pb-2 d-flex flex-row justify-content-between align-items-center align-content-center'>
                    <h5>Dive into the Unknown</h5>
                    <span className='icon'>
                      <MdImageSearch />
                    </span>
                  </div>
                  <p className=' text-justify'>
                    Discover uncharted territories of knowledge with our
                    exclusive guest speakers and thought-provoking workshops.
                  </p>
                </div>

                {/* card 3 */}
                <div
                  className='col-lg-3 col-md-4 col-12  card'
                  data-aos='fade-down'
                >
                  <div className=' pb-2 d-flex flex-row justify-content-between align-items-center align-content-center'>
                    <h5>Ignite Lifelong Connections</h5>
                    <span className='icon'>
                      <LiaConnectdevelop />
                    </span>
                  </div>
                  <p className='text-justify'>
                    Connect with an incredible community of like-minded peers
                    and mentors who will inspire and support you.
                  </p>
                </div>
              </div>
            </div>
            <div className='d-flex gap-lg-2 gap-1 justify-content-center'>
              <div>
                <button className='btn px-lg-4 btn-1 btn-lg'>
                  Lead a Club
                </button>
              </div>
              <div>
                <a href='/club'>
                  <button className='btn-outline'>Join a Club</button>
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export const FooterSection = () => {

  return (
    <>
      <div className='row mx-0 py-5 justify-content-center footer'>
        <div className='col-11 px-0'>
          <section className=' padding-x padding-y'>
            <div className='row mx-0 gap-4 gap-md-0 align-items-center'>
              <div className='col-md-7 col-12 padding-y text-left'>
                <h1 className='text-capitalize ' data-aos='fade-up'>
                  So Many People are <span>engaged</span> all over the world
                </h1>
                <h6 className='py-md-4 py-2'>
                  Speed doesn’t matter when progress start working, let’s
                  emphasize on learning and reframe the world with magical power
                </h6>
                <div>
                  <a
                    href='https://chat.whatsapp.com/CEGmMHdDZk21kiPIgAocAp'
                    target='_blank'
                  >
                    <button className='btn-lg btn'>Join Community</button>
                  </a>
                </div>
              </div>
              <div className='col-md-5 col-12 px-0 text-lg-right'>
                <img src={`http://res.cloudinary.com/datacode/image/upload/v1731595731/p6rkndc1uuxjp3xscbff.png`} data-aos='fade-up' alt='' />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export const Section = () => {
  return (
    <>
      <section className='section'></section>
    </>
  );
};
