import React from "react";

const ExploreOurLearningProcess = () => {
  return (
    <div className="exploreOurLearningProcess">
      <h1 className="text-center py-5">Explore Our Learning Resources</h1>
      <div
        className="row mx-0 justify-content-center"
        style={{ background: "#7848f4" }}
      >
        <div className="col-11 col-md-10 col-lg-10 px-4">
          {/* row - 1 */}
          <div className="row mx-0 py-4">
            <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center order-1">
              <div>
                <h2 className="text-light">Structured Learning Paths</h2>
                <p
                  className="text-light py-3 text-justify"
                  style={{ fontSize: "16px" }}
                >
                  Begin with understanding the core concepts and breaking them
                  into manageable steps. Focus on building a strong foundation
                  by practicing simple tasks and gradually increase the
                  complexity as you progress. Consistency and hands-on practice
                  are key to mastering any skill effectively.
                </p>
                <div>
                  <button
                    type="button"
                    className="px-4 py-2"
                    style={{
                      background: "#fff",
                      color: "#7848f4",
                      border: "none",
                      borderRadius: "20px",
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 order-2">
              <div className="py-5 py-md-0 py-lg-0">
                <img
                  src={`http://res.cloudinary.com/datacode/image/upload/v1731929666/cmawnrs3jl2hv7scj4ob.jpg`}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          {/* row 2 */}
          <div className="row mx-0 py-4">
            <div className="col-12 col-md-6 col-lg-6 order-2 order-md-1 order-lg-1">
              <div className="py-5 py-md-0 py-lg-0">
                <img
                  src={`http://res.cloudinary.com/datacode/image/upload/v1732091521/radnkjf9ovhkuo0v837a.png`}
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center order-1 order-md-2 order-lg-2">
              <div>
                <h2 className="text-light">Tutoials And Courses</h2>
                <p
                  className="text-light py-3 text-justify"
                  style={{ fontSize: "16px" }}
                >
                  Tutorials and courses are valuable resources for learning new
                  skills, offering structured guidance and practical examples.
                  They provide step-by-step instructions, making it easier to
                  understand complex concepts. Choosing the right course can
                  help you build a solid foundation and progress efficiently.
                </p>
                <div>
                  <button
                    type="button"
                    className="px-4 py-2"
                    style={{
                      background: "#fff",
                      color: "#7848f4",
                      border: "none",
                      borderRadius: "20px",
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* row - 3 */}
          <div className="row mx-0 py-4">
            <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center order-1">
              <div>
                <h2 className="text-light">Practice Questions & Quizzes</h2>
                <p
                  className="text-light py-3 text-justify"
                  style={{ fontSize: "16px" }}
                >
                  Practice questions and quizzes are excellent tools for
                  reinforcing knowledge and identifying gaps in understanding.
                  They provide hands-on experience and help solidify concepts
                  through application. Regular practice boosts confidence and
                  improves problem-solving skills effectively.
                </p>
                <div>
                  <button
                    type="button"
                    className="px-4 py-2"
                    style={{
                      background: "#fff",
                      color: "#7848f4",
                      border: "none",
                      borderRadius: "20px",
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 order-2">
              <div className="py-5 py-md-0 py-lg-0">
                <img
                  src={`http://res.cloudinary.com/datacode/image/upload/v1731929666/cmawnrs3jl2hv7scj4ob.jpg`}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          {/* row - 4 */}
          <div className="row mx-0 py-4">
            <div className="col-12 col-md-6 col-lg-6 order-2 order-md-1 order-lg-1">
              <div className="py-5 py-md-0 py-lg-0">
                <img
                  src={`http://res.cloudinary.com/datacode/image/upload/v1731929666/cmawnrs3jl2hv7scj4ob.jpg`}
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center order-1 order-md-2 order-lg-2">
              <div>
                <h2 className="text-light">Technical Blogs</h2>
                <p
                  className="text-light py-3 text-justify"
                  style={{ fontSize: "16px" }}
                >
                  Technical blogs are a great way to deepen your understanding
                  of complex topics and stay updated on industry trends. They
                  often provide real-world insights, practical examples, and
                  tips from experts. Reading and writing technical blogs can
                  enhance both knowledge and communication skills.
                </p>
                <div>
                  <button
                    type="button"
                    className="px-4 py-2"
                    style={{
                      background: "#fff",
                      color: "#7848f4",
                      border: "none",
                      borderRadius: "20px",
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExploreOurLearningProcess;
