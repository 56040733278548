import React from "react";
import { LuPartyPopper } from "react-icons/lu";
import Carousel from "react-multi-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const EventGlimpse = () => {
  const images = [
    // "http://res.cloudinary.com/datacode/image/upload/v1733349341/vyw3aezn2quocolethky.jpg",
    "http://res.cloudinary.com/datacode/image/upload/v1733349389/xfqjkhpo7xmklraghe0m.jpg",
    "http://res.cloudinary.com/datacode/image/upload/v1733349425/g5du9ijq9lsbwsqpcocw.jpg",
    "http://res.cloudinary.com/datacode/image/upload/v1733349458/iim3lj3ivt7mjdcbwqw1.jpg",
    "http://res.cloudinary.com/datacode/image/upload/v1733349489/ltzdojvuyp7hxvbenyyb.jpg",
    "http://res.cloudinary.com/datacode/image/upload/v1733349523/l9l2zwiejl3yjzusbgph.jpg",
    // "http://res.cloudinary.com/datacode/image/upload/v1733349560/mo2cecsvyf0ehl34pakp.jpg",
    "http://res.cloudinary.com/datacode/image/upload/v1733349595/s9ynxeyax0lyubssuufj.jpg",
    "http://res.cloudinary.com/datacode/image/upload/v1733349643/gkuwy7nto631ry5rtmb5.jpg",
    "http://res.cloudinary.com/datacode/image/upload/v1733349643/gkuwy7nto631ry5rtmb5.jpg",
    "http://res.cloudinary.com/datacode/image/upload/v1733349726/zjw3yuugqyicwbljcxd6.jpg",
    "http://res.cloudinary.com/datacode/image/upload/v1733349643/gkuwy7nto631ry5rtmb5.jpg",
    "http://res.cloudinary.com/datacode/image/upload/v1733349752/mwnzn5zsilfwmvuduj6f.jpg",
    "http://res.cloudinary.com/datacode/image/upload/v1733349823/f43n9nqxmg06y5kiwopo.jpg",
    "http://res.cloudinary.com/datacode/image/upload/v1733349856/rhcfwznbdmtxshaf1bet.jpg",
    "http://res.cloudinary.com/datacode/image/upload/v1733349489/ltzdojvuyp7hxvbenyyb.jpg",
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const responsive1 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    arrows: false,
    pauseOnHover: false,
  };
  return (
    <div>
      <div className='row mx-0 justify-content-center  AiSummitEventGlimpse py-lg-5'>
        <div className='col-10  px-0'>
          <h1 className='text-center text-light d-flex justify-content-center '>
            <span> Event Highlights</span>
            <LuPartyPopper color='orange' className='mx-2' />
          </h1>

          <div className='col-12'>
            <div className='row mx-0 justify-content-center'>
              <div className='col-12 '>
                <div className='row mx-0 my-4'>
                  <div className='col-9  px-0'>
                    <Carousel
                      responsive={responsive1}
                      autoPlay={true}
                      infiniteLoop={true}
                      showDots={false}
                      showArrows={false}
                      showThumbs={false}
                      transitionDuration={500}
                      pauseOnHover={false}
                      className='mx-0 px-0'
                    >
                      {images.map((image, index) => (
                        <div key={index} className='col-12 px-0'>
                          <img
                            src={image}
                            alt='Event_img'
                            className='w-100  
                            main-image
                            '
                          />
                        </div>
                      ))}
                    </Carousel>

                    <Carousel
                      responsive={responsive}
                      autoPlay={true}
                      infiniteLoop={true}
                      showDots={false}
                      showArrows={false}
                      showThumbs={false}
                      pauseOnHover={false}
                      className='mx-0 px-0'
                      transitionDuration={700}
                    >
                      {images.slice(1).map((image, index) => (
                        <div key={index} className='col-12 pl-0 mt-4'>
                          <img
                            src={image}
                            alt='Event_img'
                            className='w-100
                            side-images
                            '
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  <div className='col-3 pl-4 pr-0 h-100'>
                    <Slider {...sliderSettings} speed={1000} easing='ease-out'>
                      {images.slice(0, 4).map((image, index) => (
                        <div
                          key={index}
                          className='col-12 px-0 h-100 custom-bottom-margin pt-0 mt-0'
                        >
                          <img
                            src={image}
                            alt='Event_img'
                            className='w-100 side-images'
                          />
                        </div>
                      ))}
                    </Slider>
                    <Slider {...sliderSettings} speed={1000} easing='ease-out'>
                      {images.slice(4, 8).map((image, index) => (
                        <div
                          key={index}
                          className='col-12 px-0 h-100 custom-bottom-margin pt-0 mt-0'
                        >
                          <img
                            src={image}
                            alt='Event_img'
                            className='w-100  side-images'
                          />
                        </div>
                      ))}
                    </Slider>
                    <Slider {...sliderSettings} speed={1000} easing='ease-out'>
                      {images.slice(8, 12).map((image, index) => (
                        <div
                          key={index}
                          className='col-12 px-0 h-100 custom-bottom-margin pt-0 mt-0'
                        >
                          <img
                            src={image}
                            alt='Event_img'
                            className='w-100  side-images'
                          />
                        </div>
                      ))}
                    </Slider>
                    <Slider {...sliderSettings} speed={1000} easing='ease-out'>
                      {images.slice(12, 16).map((image, index) => (
                        <div
                          key={index}
                          className='col-12 px-0 h-100 py-0 mt-0'
                        >
                          <img
                            src={image}
                            alt='Event_img'
                            className='w-100  side-images'
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventGlimpse;
