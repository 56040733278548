import React, { Component } from "react";
import { faConnectdevelop } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class RightSideSection extends Component {
  state = {};
  render() {
    return (
      <>
        {/* Desktop Version */}
        <div className='container-fluid px-0 text-center text-md-center mb-5  d-none d-sm-block rounded-lg pt-5 '>
          <div className='card border  mt-5 p-3 '>
            <div className='d-flex align-items-center justify-content-center '>
              <img
                className='  mx-auto img-fluid img-circle d-block '
                src={require("../../assets/images/svg/monolog.svg")}
                alt='avatar'
                height={80}
                width={80}
              />
              <h6 className='mb-0 h-100 ' style={{ color: "#072C71" }}>
                Datacode Learning Community
              </h6>
            </div>
            <div className='card-body post-card text-center'>
              <p className='card-text'>
                Datacode is a Online Learning Community to find and share the
                best learning courses and tutorials.
              </p>
            </div>
            <div className='card-footer border border-white bg-white text-center'>
              <a
                href='https://www.facebook.com/Datacode.in/'
                className='btn font-weight-bold'
                style={{ backgroundColor: "#30006b", color: "#ffffff" }}
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesomeIcon icon={faConnectdevelop} /> Join us
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RightSideSection;
