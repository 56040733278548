import React from "react";
import { OurSponsor } from "./Constants";

export default function AiSummitOurSponsor() {
  return (
    <div className="row mx-0 justify-content-center AiSummitOurSponsor  py-lg-5">
      <div className="col-lg-10  px-0">
        <h1 className="text-center">
          <i className="fa fa-users mr-lg-3 mr-2" />
          Our Sponsor
        </h1>

        <div className="row mx-0 pt-5">
          <div className="col-12">
            <div className="d-flex flex-wrap gap-lg-4 gap-0 justify-content-center align-items-center">
              {OurSponsor.map((item, i) => (
                <div key={i} className="">
                  <div className="">
                    <img src={item.img1} loading="lazy" alt="img" width={200} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
