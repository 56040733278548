import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Story = () => {
  const storyDetails = [
    {
      name: "Divya Attarde",
      profession: "React Developer",
      bio: "Divya Attarde is a BTech student at SAIT and a React Developer at DATACODE.IN. He works on creating dynamic, responsive web applications while refining his skills in front-end technologies.",
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1731947415/xetdwsfrxstbwbpagg7h.png",
    },
    {
      name: "Poorti Jain",
      profession: "React Developer",
      bio: " I’m Poorti Jain, a React developer recently completed MCA. I enjoy learning new technologies and continuously improving my skills in software development.",
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1731946937/jiu8hatj06rgbx70qlky.png",
    },
    {
      name: "Aryan Karma",
      profession: "React Developer",
      bio: "I’m Aryan Karma, a passionate React developer with a talent for creating dynamic and user-friendly web applications. I enjoy building efficient, scalable front-end solutions.",
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1731999476/xwlgbzgodgb9tjm513xx.png",
    },
    {
      name: "Smriti Jain",
      profession: "React Developer",
      bio: "I’m Smriti, a dedicated React developer with a passion for crafting seamless and interactive user experiences. I enjoy building scalable front-end applications in web development.",
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1732198750/a6p41uy2fghxtlvphzhh.png",
    },
    {
      name: "Sheetal Chouhan",
      profession: "React Developer",
      bio: "I’m Sheetal, a passionate React developer focused on building dynamic and responsive web applications. I thrive on creating seamless user experiences and constantly seek to improve my skills.",
      imageUrl:
        "http://res.cloudinary.com/datacode/image/upload/v1732264312/a5tjhmdl28xv8mizfbb2.png",
    },
  ];
  return (
    <>
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay
        autoPlaySpeed={2000}
        centerMode={false}
        className=''
        containerClass='container'
        dotListClass=''
        draggable
        focusOnSelect={false}
        infinite
        itemClass=''
        keyBoardControl
        minimumTouchDrag={80}
        partialVisible
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={true}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 1,
            partialVisibilityGutter: 300,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 0,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 1,
            partialVisibilityGutter: 150,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=''
        slidesToSlide={1}
        swipeable
      >
        {storyDetails.map((item, index) => {
          return (
            <div className='story-item' key={index}>
              <div className='d-flex gap-0 align-items-center mb-3'>
                <img
                  src={item.imageUrl}
                  className='rounded-pill border'
                  height={35}
                  width={35}
                />
                <div className='story-header pl-3 d-flex align-items-center'>
                  <div>
                  <h6 className="text-left">{item.name}</h6>
                  <p className="text-left">{item.profession}</p>
                  </div>
                </div>
              </div>
              <p className='text-justify'>{item.bio}</p>
            </div>
          );
        })}
      </Carousel>
    </>
  );
};
