export const quizTests = [
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731169083/ksv25dx4glbemsmu9u8z.jpg",
    title: "C Programming Quiz Test",
    paragraph: "",
    link: "/c_quize_tests",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731169103/tjhl4coyvtlbftcsnaac.jpg",
    title: "Python Programming Quiz Test",
    paragraph: "",
    link: "/python_quize_tests",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731169203/fq5r4sobilllurzr3nwv.jpg",
    title: "Java Programming Quiz Test",
    paragraph: "",
    link: "/java_quize_tests",
  },

  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731169232/spgqkqm3iqkctgwdyw7t.jpg",
    title: "HTML & CSS Quiz Test",
    paragraph: "",
    link: "/html_quize_tests",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731169265/juzusy8ftumuxxarihno.jpg",
    title: "JavaScript Programming Quiz Test",
    paragraph: "",
    link: "/js_quize_tests",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731169291/gbyj1woek3smjgoo7gcp.jpg",
    title: "DataStructure Quiz Test",
    paragraph: "",
    link: "/ds_quize_tests",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731169316/v0arm9bmnugkyluivb27.jpg",
    title: "DBMS Quiz Test",
    paragraph: "",
    link: "/dbms_quize_tests",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731169344/vfbbsuytfcwdrjnaqhf1.jpg",
    title: "SQL Quiz Test",
    paragraph: "",
    link: "/sql_quize_tests",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731169369/scliwp8ssigcxpu9ufqn.jpg",
    title: "OOPs Programming Quiz Test",
    paragraph: "",
    link: "/oops_quize_tests",
  },

  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731169403/j9ejuquujnb76rmjp78z.jpg",
    title: "Angular Quiz Test",
    paragraph: "",
    link: "/angular_quize_tests",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731169433/xwb46qsei0d3vqcrol1t.jpg",
    title: "React Quiz Test",
    paragraph: "",
    link: "/react_quize_tests",
  },
];
