import React, { Component } from "react";
import { Link } from "react-router-dom";
class CardCarouselItem extends Component {
  state = {};
  render() {
    const { imgsource } = this.props;
    return (
      <>
        <div className='card post-card text-center shadow'>
          <img
            className=' card-img-top mx-auto img-fluid img-circle d-block'
            // src={require("../../../assets/images/" + this.props.imgsource)}
            src={
              imgsource.includes("cloudinary")
                ? imgsource
                : require("../../../assets/images/" + this.props.imgsource)
            }
            alt='avatar'
          />
          <h5 className='text-center mt-2'>{this.props.title}</h5>
          <div
            className='card-footer p-1 mt-1'
            style={{ backgroundColor: "#30006b" }}
          >
            <Link to={this.props.link}>
              <div className='btn btn-block p-0 m-0 border-0'>
                <span
                  className='mt-1 p-0'
                  style={{
                    color: "white",
                  }}
                >
                  Start Learning
                  <i className='ml-2 fal fa-long-arrow-right' />
                </span>
              </div>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default CardCarouselItem;
