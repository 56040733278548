import React from "react";
import PropTypes from "prop-types";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page) => {
    if (page >= 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const handlePrevClick = () => {
    if (currentPage > 0) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages - 1) {
      handlePageChange(currentPage + 1);
    }
  };

  // const renderPageNumbers = () => {
  //   const pageNumbers = [];
  //   for (let i = 0; i < totalPages; i++) {
  //     pageNumbers.push(
  //       <li
  //         key={i}
  //         className={`page-number ${i === currentPage ? 'active' : ''}`}
  //         onClick={() => handlePageChange(i)}
  //       >
  //         {i + 1}
  //       </li>
  //     );
  //   }
  //   return pageNumbers;
  // };
  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= 6) {
      for (let i = 0; i < totalPages; i++) {
        pageNumbers.push(
          <li
            key={i}
            className={`page-number ${i === currentPage ? "active" : ""}`}
            onClick={() => handlePageChange(i)}
          >
            {i + 1}
          </li>,
        );
      }
    } else {
      pageNumbers.push(
        <li
          key={0}
          className={`page-number ${currentPage === 0 ? "active" : ""}`}
          onClick={() => handlePageChange(0)}
        >
          1
        </li>,
      );
      if (currentPage > 2) {
        pageNumbers.push(
          <li key='ellipsis-start' className='text-secondary'>
            ...
          </li>,
        );
      }

      const startPage = Math.max(1, currentPage - 1);
      const endPage = Math.min(totalPages - 2, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <li
            key={i}
            className={`page-number ${i === currentPage ? "active" : ""}`}
            onClick={() => handlePageChange(i)}
          >
            {i + 1}
          </li>,
        );
      }

      if (currentPage < totalPages - 3) {
        pageNumbers.push(
          <li key='ellipsis-end' className='text-secondary'>
            ...
          </li>,
        );
      }

      if (totalPages > 1) {
        pageNumbers.push(
          <li
            key={totalPages - 1}
            className={`page-number ${
              currentPage === totalPages - 1 ? "active" : ""
            }`}
            onClick={() => handlePageChange(totalPages - 1)}
          >
            {totalPages}
          </li>,
        );
      }
    }

    return pageNumbers;
  };

  return (
    <ul className='pagination'>
      <li
        className={`prev ${currentPage === 0 ? "disabled" : ""}`}
        onClick={handlePrevClick}
      >
        Prev
      </li>
      {renderPageNumbers()}
      <li
        className={`next ${currentPage === totalPages - 1 ? "disabled" : ""}`}
        onClick={handleNextClick}
      >
        Next
      </li>
    </ul>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
