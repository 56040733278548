import React, { useEffect } from "react";
import AOS from "aos";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FcLike } from "react-icons/fc";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const testimonialData = [
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731935665/am3nfwet1uzprogb8yiw.png`,
    name: "Poorti Jain",
    college: "SVIMS",
    designation: "React Developer",
    review:
      "Working with this team has been an incredible experience! Their expertise and attention to detail ensured our project's success, exceeding all expectations. The seamless communication and dedication to delivering high-quality results truly set them apart. Highly recommended for anyone seeking top-notch service!",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1732199056/lrgvzm7wydfmxgb1dlt1.png`,
    name: "Divya Attarde",
    college: "SAIT",
    designation: "React Developer",
    review:
      "Interning at Datacode was a fantastic learning journey. The projects were challenging yet rewarding, and the guidance from the team helped me enhance my technical and analytical skills. It was a great opportunity to develop both personally and professionally.",
  },
  // {
  //   image1: `http://res.cloudinary.com/datacode/image/upload/v1731935665/am3nfwet1uzprogb8yiw.png`,
  //   name: "Preety Arya",
  //   college: "CDGI",
  //   designation: "React Developer",
  //   review:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias pariatur sit culpa perferendis et possimus animi, temp assumenda, voluptatibus repellendus magnam deserunt incidunt optio excepturi quae ipsum reprehenderit nemo sed?",
  // },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1732198750/a6p41uy2fghxtlvphzhh.png`,
    name: "Smriti Jain",
    college: "xyz",
    designation: "React Developer",
    review:
      "My internship at Datacode was a highly enriching experience. I gained hands-on exposure to real-world projects, enhanced my technical skills, and learned the importance of teamwork and problem-solving. The supportive mentors and collaborative environment made it an excellent place to grow professionally.",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731999476/xwlgbzgodgb9tjm513xx.png`,
    name: "Aryan Karma",
    college: "Sage University",
    designation: "React Developer",
    review:
      "My internship at Datacode.in was an enriching experience. Working on impactful projects for an ed-tech platform helped me grow professionally while contributing meaningfully to education and technology.",
  },

  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1732264312/a5tjhmdl28xv8mizfbb2.png`,
    name: "Sheetal Chouhan",
    college: "SVIMS",
    designation: "React Developer",
    review:
      "I had the incredible opportunity to intern with DataCode, and it has been an enriching experience that significantly contributed to my professional growth. From day one, the team was welcoming and supportive, providing me with valuable guidance and constructive feedback.",
  },
];

const Testimonials = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="row mx-0 justify-content-center projects my-4">
      <div className="col-11 col-md-8 col-lg-8 px-0">
        <h1 className="text-center pb-5">
          Words Of Our <span>Intern</span>
        </h1>
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows={true}
          autoPlay
          autoPlaySpeed={5000}
          centerMode={false}
          className=""
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          partialVisible
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={true}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {testimonialData.map((items) => (
            <div className="row mx-0 container mb-4">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 px-0 box1 d-flex justify-content-center">
                <div className="">
                  <img src={items.image1} className="" height={300} />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 box2 px-0">
                <div className="p-4">
                  <h4 className="text-light">
                    {items.name} <FcLike />
                  </h4>
                  <h6 className="text-light py-2">{items.college}</h6>
                  <p className="py-2 text-light">{items.review}</p>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
export default Testimonials;
