import { Checkbox, Radio } from "antd";
import Parser from "html-react-parser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  FaArrowLeft,
  FaBookmark,
  FaComment,
  FaEllipsisH,
  FaEye,
  FaHeart,
  FaShareAlt,
} from "react-icons/fa";
import { LuClipboardType } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokai } from "react-syntax-highlighter/dist/esm/styles/hljs";
import CustomLoader from "../../../shared_components/CustomLoader";
import ToolTip from "../../../shared_components/Tooltip";

import {
  clearLessonDetails,
  getAllLessons,
  getLessonDetails,
} from "../DaysCode/actions";
import CodePenBlockPreview from "./CodePenBlockPreview";
import GistBlockPreview from "./GistBlockPreview";
import YoutubeBlockPreview from "./YoutubeBlockPreview";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { IoBulbOutline } from "react-icons/io5";
import { BsInfoCircle } from "react-icons/bs";
import { AiFillNotification } from "react-icons/ai";
import { FaNoteSticky } from "react-icons/fa6";
import { HiMiniMagnifyingGlassCircle } from "react-icons/hi2";
import { IoIosWarning } from "react-icons/io";

const BlogPreview = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { lessonDetails, lessonsList, problemsListLoading } = useSelector(
    (state) => state.dayscode,
  );
  const [isLiked, setIsLiked] = useState(false);
  const [quizSingleOption, setQuizSingleOption] = useState("");
  const [quizMultiOption, setQuizMultiOption] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllLessons());
      await dispatch(getLessonDetails(id));
      setIsLoading(false);
    };

    fetchData();
    return () => {
      dispatch(clearLessonDetails());
    };
  }, []);

  const blog = {
    tags: ["React", "JavaScript", "Web Development"],
  };

  if (isLoading || problemsListLoading || !lessonDetails) {
    return <CustomLoader />;
  }

  return (
    <>
      <div className='back-btn-container d-none m-3 d-md-block '>
        <button
          className='back-button btn btn-link text-decoration-none '
          onClick={() => history.push("/blogs")}
        >
          <FaArrowLeft /> Back to Blogs
        </button>
      </div>
      <div className='d-flex  justify-content-center'>
        <div className=' pb-5  d-flex flex-column col-md-10 blogs blog-w '>
          <article className='blog-preview mt-3 '>
            <div className='row justify-content-start'>
              <div className='col-md-9 blog-preview-title'>
                <h1 className='display-4 mb-3 '>{lessonDetails?.name}</h1>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic
                  aut nobis assumenda itaque, culpa fugiat? Lorem ipsum dolor,
                  sit amet consectetur adipisicing elit.
                </p>
                <div className='d-flex align-items-center gap-3 mb-3 '>
                  <span className='text-muted d-flex align-items-center'>
                    <img
                      className=' mr-2 border rounded-pill'
                      height={45}
                      width={45}
                      src={
                        lessonDetails.author?.imgUrl ||
                        "https://miro.medium.com/v2/resize:fill:20:20/1*abnkL8PKTea5iO2Cm5H-Zg.png"
                      }
                      alt=''
                    />
                    <span className='title-name'>
                      <b>
                        <span className='author-name'>
                          {lessonDetails.author
                            ? lessonDetails.author?.firstName +
                              " " +
                              lessonDetails.author?.lastName
                            : "Divy Attarde"}
                        </span>
                      </b>
                      <span className='text-muted d-flex align-items-center'>
                        Publised on{" "}
                        {moment(lessonDetails?.createdAt).format(
                          "MMMM Do YYYY",
                        )}
                      </span>
                    </span>
                  </span>
                </div>
                <div className='blog-title-footer'>
                  <span className='text-muted d-flex align-items-center'>
                    Last Updated on{" "}
                    {moment(lessonDetails?.updatedAt).format("MMMM Do YYYY")} ●
                    10 minute read
                  </span>
                  <span className='share'>
                    <span>
                      <FaFacebookSquare />
                    </span>
                    <span>
                      <FaSquareXTwitter />
                    </span>
                    <span>
                      <FaLinkedin />
                    </span>
                  </span>
                </div>
              </div>
              <div className='col-md-12 '>
                <div className='row'>
                  <div className=' col-12 col-md-9'>
                    <img
                      src={lessonDetails.thumbnail}
                      className='img-fluid mb-3 animate__animated animate__fadeIn w-100 '
                      alt={lessonDetails.name}
                    />

                    <div className='mb-4'>
                      <div className='d-flex flex-wrap justify-content-between align-items-center mb-3'>
                        <span className='badge bg-primary px-2 py-2 text-white blog-preview-badge'>
                          <b>{lessonDetails.category}</b>
                        </span>
                      </div>

                      <div className='d-flex flex-wrap gap-2 mb-4'>
                        {blog.tags.map((tag) => (
                          <span key={tag} className='badge bg-light text-dark'>
                            {tag}
                          </span>
                        ))}
                      </div>
                    </div>

                    <div className='blog-content '>
                      {lessonDetails?.description
                        ?.split("\n\n")
                        .map((paragraph, index) => (
                          <p key={index} className='content'>
                            {paragraph}
                          </p>
                        ))}
                    </div>

                    <div>
                      {lessonDetails?.contents?.map((content) => {
                        return (
                          <div className='textEditor'>
                            {content.type === "textEditor" && (
                              <div>
                                <p>{Parser(String(content.payload))}</p>
                              </div>
                            )}
                            {content.type === "quizSingleOption" && (
                              <div className=' form-check d-flex flex-column border shadow-sm py-3 rounded-lg mb-4'>
                                <label className='border-bottom mr-2'>
                                  <b>{content.payload.question}?</b>
                                </label>
                                <Radio.Group
                                  className='d-flex flex-column'
                                  // value={quizSingleOption}
                                  onChange={(e) => {
                                    e.target.value ===
                                      content.payload.option1 &&
                                      setQuizSingleOption("option1");
                                    e.target.value ===
                                      content.payload.option2 &&
                                      setQuizSingleOption("option2");
                                    e.target.value ===
                                      content.payload.option3 &&
                                      setQuizSingleOption("option3");
                                    e.target.value ===
                                      content.payload.option4 &&
                                      setQuizSingleOption("option4");
                                  }}
                                >
                                  <Radio
                                    value={content.payload.option1}
                                    className='border rounded my-2 p-2'
                                  >
                                    {content.payload.option1}
                                  </Radio>
                                  <Radio
                                    value={content.payload.option2}
                                    className='border rounded my-2 p-2'
                                  >
                                    {content.payload.option2}
                                  </Radio>
                                  <Radio
                                    value={content.payload.option3}
                                    className='border rounded my-2 p-2'
                                  >
                                    {content.payload.option3}
                                  </Radio>
                                  <Radio
                                    value={content.payload.option4}
                                    className='border rounded my-2 p-2'
                                  >
                                    {content.payload.option4}
                                  </Radio>
                                </Radio.Group>
                                <div className='blog-content-btn'>
                                  <button
                                    className='btn btn-success float-right '
                                    onClick={() => {
                                      console.log(
                                        content.payload.correct_answer ===
                                          quizSingleOption && "correct",
                                      );
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            )}
                            {content.type === "quizMultiOption" && (
                              <div className='form-check d-flex flex-column border py-3 shadow-sm rounded-lg mb-4'>
                                <label className='border-bottom mr-2'>
                                  <b>{content.payload.question}?</b>
                                </label>
                                <Checkbox.Group className='d-flex flex-column'>
                                  <Checkbox
                                    value={content.payload.text1}
                                    className='border rounded my-2 p-2 mr-2'
                                    onChange={(e) => {
                                      setQuizMultiOption(e.target.value);
                                    }}
                                  >
                                    {content.payload.text1}
                                  </Checkbox>
                                  <Checkbox
                                    value={content.payload.text2}
                                    className='border rounded my-2 p-2  mr-2'
                                    onChange={(e) => {
                                      setQuizMultiOption(e.target.value);
                                    }}
                                  >
                                    {content.payload.text2}
                                  </Checkbox>
                                  <Checkbox
                                    value={content.payload.text3}
                                    className='border rounded my-2 p-2  mr-2'
                                    onChange={(e) => {
                                      setQuizMultiOption(e.target.value);
                                    }}
                                  >
                                    {content.payload.text3}
                                  </Checkbox>
                                  <Checkbox
                                    value={content.payload.text4}
                                    className='border rounded my-2 p-2  mr-2'
                                    onChange={(e) => {
                                      setQuizMultiOption(e.target.value);
                                    }}
                                  >
                                    {content.payload.text4}
                                  </Checkbox>
                                </Checkbox.Group>
                                <div className='blog-content-btn'>
                                  <button className='btn btn-success float-right '>
                                    Submit
                                  </button>
                                </div>
                              </div>
                            )}
                            {content.type === "textEditorElements" && (
                              <div className='my-5 textEditorElements'>
                                {content.subType === "tip" && (
                                  <div className='tip rounded-lg'>
                                    <div className='head px-3 d-flex align-items-center'>
                                      <span>
                                        <IoBulbOutline
                                          size={25}
                                          className='mr-1'
                                        />
                                      </span>
                                      <span>{content.subType}</span>
                                    </div>
                                    <div className='p-3 content'>
                                      {Parser(String(content?.payload))}
                                    </div>
                                  </div>
                                )}
                                {content.subType === "info" && (
                                  <div className='info rounded-lg'>
                                    <div className='head px-3'>
                                      <BsInfoCircle className='mr-1' />
                                      {content.subType}
                                    </div>
                                    <div className='p-3 content'>
                                      {Parser(String(content?.payload))}
                                    </div>
                                  </div>
                                )}
                                {content.subType === "important" && (
                                  <div className='important rounded-lg'>
                                    <div className='head px-3'>
                                      <AiFillNotification className='mr-1' />
                                      {content.subType}
                                    </div>
                                    <div className='p-3 content'>
                                      {Parser(String(content?.payload))}
                                    </div>
                                  </div>
                                )}
                                {content.subType === "note" && (
                                  <div className='note rounded-lg'>
                                    <div className='head px-3'>
                                      <FaNoteSticky className='mr-1' />
                                      {content.subType}
                                    </div>
                                    <div className='p-3 content'>
                                      {Parser(String(content?.payload))}
                                    </div>
                                  </div>
                                )}
                                {content.subType === "fact" && (
                                  <div className='fact rounded-lg'>
                                    <div className='head px-3'>
                                      <HiMiniMagnifyingGlassCircle
                                        className='mr-1'
                                        size={25}
                                      />
                                      {content.subType}
                                    </div>
                                    <div className='p-3 content'>
                                      {Parser(String(content?.payload))}
                                    </div>
                                  </div>
                                )}
                                {content.subType === "warning" && (
                                  <div className='warning rounded-lg'>
                                    <div className='head px-3'>
                                      <IoIosWarning
                                        className='mr-1'
                                        size={25}
                                      />
                                      {content.subType}
                                    </div>
                                    <div className='p-3 content'>
                                      {Parser(String(content?.payload))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {content.type === "codeBlock" && (
                              <div className='my-5'>
                                <div className='border-top border-left border-right rounded'>
                                  <div className='d-flex justify-content-between'>
                                    <h5 className='pt-2 px-2'>Code Block</h5>
                                    <ToolTip
                                      id={"copytoClip"}
                                      message={"Copy to clipboard"}
                                      placement={"right"}
                                    />
                                    <h5
                                      className='pt-2 px-2 copytoClip'
                                      id='copytoClip'
                                    >
                                      <LuClipboardType />
                                    </h5>
                                  </div>
                                  <SyntaxHighlighter
                                    language={content?.payload?.language}
                                    style={monokai}
                                  >
                                    {content?.payload?.code}
                                  </SyntaxHighlighter>
                                </div>{" "}
                              </div>
                            )}{" "}
                            {content.type === "imageBlock" && (
                              <div className='my-5 '>
                                <h4 className='text-center mb-2 blog-headings'>
                                  Image Resources
                                </h4>
                                <img
                                  src={content?.payload}
                                  alt={"img-block"}
                                  height={"100%"}
                                  width={"100%"}
                                />
                              </div>
                            )}{" "}
                            {content.type === "codePenBlock" && (
                              <CodePenBlockPreview item={content.payload} />
                            )}{" "}
                            {content.type === "youtubeBlock" && (
                              <YoutubeBlockPreview item={content.payload} />
                            )}
                            {content.type === "githubGistBlock" && (
                              <div className='my-4'>
                                <GistBlockPreview item={content.payload} />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div className=' featured-blog-preview mx-0 '>
                      <h4 className='blog-headings text-center'>
                        Featured Blogs
                      </h4>
                      <div className='row justify-content-between mx-0'>
                        {lessonsList &&
                          lessonsList.slice(0, 4).map((lesson) => {
                            return (
                              <div
                                className=' col-11 col-md-6 px-0 rounded my-2 mx-auto'
                                key={lesson._id}
                              >
                                <div className='border mx-2 blog-top'>
                                  <div className='blog-img '>
                                    <img
                                      src={
                                        lesson.thumbnail ||
                                        "https://c7.alamy.com/comp/D6BK05/shadow-looming-over-frightened-boy-D6BK05.jpg"
                                      }
                                      alt={lesson?.name}
                                    />
                                  </div>
                                  <div className='d-flex justify-content-between align-items-center px-1 mt-2 blog-head'>
                                    <span className=''>
                                      <img
                                        src={
                                          lesson.author?.imgUrl ||
                                          "https://c7.alamy.com/comp/D6BK05/shadow-looming-over-frightened-boy-D6BK05.jpg"
                                        }
                                        alt='Author name'
                                        height={30}
                                        width={30}
                                        className=' rounded-pill border'
                                      />
                                      <span className='ml-1'>
                                        {lesson.author?.firstName +
                                          lesson.author?.lastName ||
                                          "Deep Giando"}
                                      </span>
                                    </span>
                                    <span>
                                      <span className='mr-2 d-felx align-items-center'>
                                        <span>
                                          <FaEye />
                                        </span>
                                        <span> 1200</span>
                                      </span>
                                      <span className='ml-2'>5 min Read</span>
                                    </span>
                                  </div>
                                  <div className='blog-content px-2 mt-2'>
                                    <h4>
                                      {lesson?.name ||
                                        "None is there but WHO??"}
                                    </h4>
                                    <p>
                                      {lesson.description
                                        ? lesson.description?.slice(0, 75) +
                                          "..."
                                        : " adipisicingelit. Voluptatum suscipit labore ad perferendis odi odi odi odi..."}
                                    </p>
                                  </div>
                                  <div className='blog-footer px-2 mb-2'>
                                    <span>
                                      <span
                                        role='img'
                                        aria-label='icon'
                                        className='mx-2'
                                      >
                                        ✨
                                      </span>
                                      <span className=''>
                                        {moment(
                                          lessonDetails?.updatedAt,
                                        ).format("MMM D")}
                                      </span>
                                      <span>
                                        <FaHeart className='mx-2' />
                                        450
                                      </span>
                                      <span>
                                        <FaComment className='mx-2' />
                                        27
                                      </span>
                                    </span>
                                    <span>
                                      <span>
                                        <FaBookmark className='mx-2' />
                                      </span>
                                      <span>
                                        <FaEllipsisH className='mx-2' />
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3 d-none d-md-block side-section mt-4 mb-0'>
                    <h4>What is Datacode?</h4>
                    <p className='mb-0'>
                      Datacode Provides you the Best free Learning Content from
                      all over the world to Improve your Skills and Make your
                      Learning Easy and Quick. Either one is at Beginners or at
                      the Advance level you find your track and learn from it.
                    </p>
                    <Link to='/'>Learn more about us</Link>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default BlogPreview;
