import React, { useEffect } from "react";
import AOS from "aos";

const programContain = [
  {
    // icon: <GoPeople />,
    icon: "fa fa-user",
    head: "3-Month Intensive Training",
    description:
      "A comprehensive program designed to transform beginners into full-fledged web developers.",
  },
  {
    icon: "fa fa-book",
    head: "Expert-Led Learning",
    description:
      "Master essential technologies like React, Node.js, HTML, and CSS with guidance from experienced mentors.",
  },
  {
    icon: "fa fa-list",
    head: "Real-World Projects",
    description:
      "that simulate industry environments, giving you hands-on experience on the real-time work.",
  },
  {
    icon: "fa fa-gear",
    head: "Zero to Hero Approach",
    description:
      "Whether you’re starting from scratch or looking to refine your skills, we help you grow from a complete beginner to a professional developer.",
  },
  {
    icon: "fa fa-industry",
    head: "Follow Industry Best Practices",
    description:
      "Learn how to code the right way—through regular code reviews, clean coding principles, and adopting the best standards.",
  },
  {
    icon: "fa fa-id-card",
    head: "Certified & Job-Ready",
    description:
      "Earn an industry-recognized certification upon successful completion, boosting your resume and increasing job prospects.",
  },
  {
    icon: "fa fa-signal",
    head: "Proven Success",
    description:
      "In just 10 months, we’ve trained over 20 students, with the majority now placed in top companies as professional developers.",
  },
  {
    icon: "fa fa-user",
    head: "1 : 1 Mentorship",
    description:
      "We Have Provied one to one mentorship to help mentees achieve their specific goals through tailored guidance.",
  },
  {
    icon: "fa fa-code",
    head: "Code Review Sessions",
    description:
      "A code review session is a collaborative process where mentor review code to ensure quality, consistency, and best practices.",
  },
];
const BecomeProfessional = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div
        className="row mx-0 BecomeProfessional justify-content-center mt-5"
        data-aos="fade-up"
      >
        <div className="col-11 col-md-10 col-lg-10 px-0">
          <h1 className="text-center" data-aos="fade-up">
            Our <span>Internship Program</span> Contains
          </h1>

          <div
            className="row mx-0 Our-Internship py-lg-5 pt-5 py-md-5"
            data-aos="fade-up"
          >
            {programContain.map((items) => (
              <div
                className="col-12 col-sm-12 col-md-4 col-lg-4 px-0 p-3"
                data-aos="fade-left"
              >
                <div className="container p-4 border shadow-sm">
                  <i
                    style={{
                      background: "#7848f4",
                      borderRadius: "50%",
                      padding: "15px",
                      color: "#fff",
                      fontSize: "20px",
                    }}
                    className={items.icon}
                  />
                  <h5 className="pt-3 pb-2">{items.head}</h5>
                  <p className="mb-0 para">{items.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default BecomeProfessional;
