import React, { useEffect, useRef, useState } from "react";
import Pagination from "../../../../../../shared_components/Pagination";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import { FaArrowRight } from "react-icons/fa6";
import { MdOutlineKeyboardArrowRight, MdOutlineMenuBook } from "react-icons/md";
import Loader from "react-loader-spinner";
import Filter from "../Filter";
import { techIcon } from "../helpers";
import TutorialCard from "../TutorialCard";

const TechTutorials = ({
  getTutorialsList,
  match: {
    params: { technology },
  },
  tutorialsList,
  tutorialListLoading,
  totalTutorials,
}) => {
  const [techDetails, setTechDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [checkedItems, setCheckedItems] = useState([]);

  // Pagination
  const tutorialsPerPage = pageLimit;
  const pageCount = Math.ceil(totalTutorials / tutorialsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // dispatch(
    //   getClubApplications({ search: searchText, page, limit: pageLimit }),
    // );
    handleTutorialList(page, checkedItems);
  };

  const handleTutorialList = async (page, tags) => {
    await getTutorialsList(technology, page, tutorialsPerPage, tags);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    handleTutorialList(currentPage, checkedItems);
    techIcon.forEach((item) => {
      if (item.technology === technology) {
        return setTechDetails(item);
      }
    });
    window.scrollTo(0, 0);
  }, []);

  const handleFilter = (event) => {
    let checkedItemsRef = checkedItems;
    if (event.target.checked && event.target.name) {
      checkedItemsRef.push(event.target.name);
      setCheckedItems(checkedItemsRef);
      handleTutorialList(0, checkedItemsRef);
    } else {
      const check = checkedItems.filter((e) => e !== String(event.target.name));
      setCheckedItems(check);
      handleTutorialList(0, check);
    }
  };

  const sectionRef = useRef(null);

  const handleScroll = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Find the Best ${
          techDetails && techDetails.title
        } Courses & Tutorials | Datacode.in`}</title>
        <meta
          data-react-helmet='true'
          name='description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          http-equiv='cleartype'
          content='on'
        ></meta>
        <meta
          data-react-helmet='true'
          name='apple-mobile-web-app-capable'
          content='yes'
        ></meta>
        <meta
          data-react-helmet='true'
          name='robots'
          content='max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:locale'
          content='en_US'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:type'
          content='website'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:title'
          content={`Find the Best ${
            techDetails && techDetails.title
          } Courses & Tutorials | Datacode.in`}
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:url'
          content='https://www.datacode.in/'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:site_name'
          content='Datacode.in'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:secure_url'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:width'
          content='400'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:height'
          content='400'
        ></meta>
        <meta
          data-react-helmet='true'
          name='keywords'
          content='programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:card'
          content='summary_large_image'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:title'
          content={`Find the Best ${
            techDetails && techDetails.title
          } Courses & Tutorials | Datacode.in`}
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:site'
          content='@datacode_in'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:creator'
          content='@datacode_in'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:image'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <link
          data-react-helmet='true'
          rel='shortlink'
          href='https://www.datacodoe.in/'
        ></link>
        <link
          data-react-helmet='true'
          rel='dns-prefetch'
          href='//www.datacode.in'
        ></link>
        <link
          data-react-helmet='true'
          rel='dns-prefetch'
          href='//s.w.org'
        ></link>
        <link
          data-react-helmet='true'
          rel='alternate'
          type='application/rss+xml'
          title='Datacode'
          href='https://www.datacode.in/'
        ></link>
        <link
          data-react-helmet='true'
          rel='canonical'
          href='https://www.datacode.in/'
        ></link>
      </Helmet>
      <div className='row m-0 category-page'>
        <div className='col-12 px-0'>
          <div className='row m-0 tech-tutorial-header'>
            <div className='col-12 px-md-5 px-2'>
              <div className='row mx-0 my-0  justify-content-center'>
                <div className='col-11 px-0'>
                  <p className='nav-link pt-2 my-3  d-flex align-items-center px-0 px-md-3'>
                    <Link to='/' className=' mx-1'>
                      Home
                    </Link>
                    <MdOutlineKeyboardArrowRight />
                    <Link to='/tutorials' className='mx-1'>
                      Tutorials
                    </Link>{" "}
                    <MdOutlineKeyboardArrowRight />
                    <Link to={`/tutorials/${technology}`} className='mx-1'>{`${
                      techDetails && techDetails.title
                    } Tutorials`}</Link>
                  </p>
                </div>
              </div>
              <div className='row m-0 px-sm-2 justify-content-center '>
                <div className='col-md-7 col-12 pt-md-4 pt-1 left-header-section px-0 px-md-2 '>
                  <h1>{techDetails && techDetails.title}</h1>
                  <h2>Tutorials & Courses</h2>
                  <p className='desktop-tutorial-text px-0'>
                    From building websites to analyzing data, the choice is
                    yours. Explore the best courses to master cutting-edge
                    technologies and unlock your potential in the digital world.
                    Whether you're diving into web development, exploring data
                    science, or learning about AI and cloud computing...
                  </p>
                  <button
                    className='btn d-none d-md-block'
                    onClick={handleScroll}
                  >
                    Explore Tutorials <FaArrowRight className='ml-1' />
                  </button>
                </div>
                <div className='col-md-4 col-12 text-center right-header-section mt-3 mt-md-0'>
                  {techIcon.map(
                    (item, i) =>
                      item.technology === technology && (
                        <img
                          key={i}
                          src={require(`../../../../../../assets/images/svg/${item.svgSource}`)}
                          alt='avatar'
                          loading='lazy'
                          className='tech-turorial-img mx-md-auto'
                        />
                      ),
                  )}
                  <button className='btn d-md-none mt-4' onClick={handleScroll}>
                    Explore Tutorials <FaArrowRight className='ml-1' />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='tutorial-course-display-section py-3 mt-5 px-3'>
            <h2 className='mt-md-5 px-3 px-md-0 mt-5  d-flex align-items-center justify-content-center'>
              <MdOutlineMenuBook fontSize={40} className='mr-2' />
              {/* <span>{`${techDetails && techDetails.title} Tutorial Hub`}</span> */}
              <span>{"Tutorial Hub"}</span>
            </h2>
            <p className='text-center pt-0 mt-0 mb-5'>
              Discover and learn from our curated collection of tutorials
            </p>
            <div className='row m-0 justify-content-center'>
              <div className='col-12 py-2 filter-section d-inline col-md-3 pt-2 main order-2 order-md-1'>
                <div className=''>
                  <Filter
                    checkedItems={checkedItems}
                    handleFilter={handleFilter}
                  />
                </div>
                <div className='add-tutorial mt-3 mb-md-0 mb-4 p-4'>
                  <div className='row mx-0'>
                    <h5 className='col-8'>
                      Add your Tutorial and contribute in communtiy
                    </h5>
                    <Link to='/add_tutorial' className='mb-2 col-4 px-2'>
                      <button className='  py-2'>
                        <span>ADD</span>
                        {/* <FaPlus size={14} className='ml-2' /> */}
                      </button>
                    </Link>
                  </div>
                  <p>
                    Share your knowledge by creating helpful tutorials that
                    educate, inspire, and contribute to the growth of the
                    community.
                  </p>
                </div>
                <div className='share-qr py-4 px-2 my-4'>
                  <div className='row mx-0'>
                    <div className='col-8'>
                      <h4>Chat with us on WhatsApp</h4>
                      <p>
                        Scan the QR code and start the chat to share your
                        queries with us.
                      </p>
                    </div>
                    <div className='col-4'>
                      <img
                        src='http://res.cloudinary.com/datacode/image/upload/v1732188778/onjvzuztopmdrqxicrlg.png'
                        alt='qr'
                        className=' w-100'
                      />
                      <div className='text-center'>
                        <span>DATACODE.IN</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='col-12 col-md-8 px-0 order-1 order-md-2'
                ref={sectionRef}
              >
                {
                  <>
                    {tutorialListLoading ? (
                      <div className='d-flex text-center justify-content-center my-5'>
                        <Loader
                          type='ThreeDots'
                          color='#161616'
                          height={50}
                          width={50}
                          timeout={11000}
                        />
                      </div>
                    ) : (
                      <div className='row mx-0 px-3 px-md-0 tutorial-card-section pl-md-4 justify-content-center justify-content-md-start'>
                        <div className='col-12 px-0 mx-0 d-block d-md-flex align-items-center justify-content-between w-100'>
                          <h4 className='mt-3 text-center'>
                            Available Tutorials ({totalTutorials})
                          </h4>
                          <div className=''>
                            {totalTutorials > 0 && (
                              <Pagination
                                currentPage={currentPage}
                                totalPages={pageCount}
                                onPageChange={handlePageChange}
                              />
                            )}
                          </div>
                        </div>
                        <div className='col-12 px-0 mb-5 d-block w-md-100'>
                          {tutorialsList && tutorialsList.length ? (
                            tutorialsList.map((item, i) => {
                              return (
                                <TutorialCard
                                  handleTutorialList={handleTutorialList}
                                  tutorial={item}
                                  id={item.id}
                                  tags={item.tags}
                                  title={item.title}
                                  likesCount={item.likes.length}
                                  key={i}
                                />
                              );
                            })
                          ) : (
                            <img
                              height='350px'
                              width='350px'
                              src={
                                "http://res.cloudinary.com/datacode/image/upload/v1731431517/sg5gtuaefwgifc8rl8hy.png"
                              }
                              alt='avatar'
                              loading='lazy'
                              className='mx-auto img-fluid img-circle'
                            />
                          )}
                          {totalTutorials > 0 && (
                            <Pagination
                              currentPage={currentPage}
                              totalPages={pageCount}
                              onPageChange={handlePageChange}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechTutorials;
