import React, { useState } from "react";
import { Modal, Button } from "antd";

const HeroSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Open the modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Handle email button
  const handleSendEmail = () => {
    const toEmail = "datacode.in@gmail.com";
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${toEmail}`;
    window.open(gmailLink, "_blank");
    setIsModalOpen(false);
  };

  return (
    <div className="row mx-0 justify-content-center my-lg-3 px-0">
      <div className="col-11 col-md-10 col-lg-10 px-0">
        <div className="row mx-0 herosectionn">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center">
            <div>
              <h1 class=" title pt-5 pt-lg-2">Internship Program</h1>
              {/* <div className="quote pt-lg-2">
                <i className="fas fa-quote-left text-left" />
              </div> */}
              <h5 class="para py-3">Step In, Skill Up, Stand Out</h5>
              <div className="d-flex justify-content-center justify-content-md-start justify-content-lg-start py-3">
                <button
                  type="button"
                  className="apply-now px-5 py-2"
                  onClick={showModal}
                >
                  Apply Now
                </button>
              </div>
              <Modal
                title="Instructions to Send Your Application"
                visible={isModalOpen}
                onCancel={handleCancel}
                footer={[
                  <Button key="cancel" onClick={handleCancel}>
                    Cancel
                  </Button>,
                  <Button key="send" type="primary" onClick={handleSendEmail}>
                    Click here to send mail
                  </Button>,
                ]}
              >
                <p>
                  Please follow the instructions below to write email for your
                  application:
                </p>
                <ul>
                  <li>Write your **Name**.</li>
                  <li>Write your **College/University Name**.</li>
                  <li>
                    Write about your **Skills and Experience** in frontend
                    technologies.
                  </li>
                  <li>Attach your **Updated Resume**.</li>
                </ul>
                <p>
                  Once you have prepared the email, send it to
                  'datacode.in@gmail.com' click the **Send Email** button in
                  Gmail to submit your application.
                </p>
              </Modal>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 justify-content-center py-5 py-lg-0">
            <div className="side-image">
              <img
                className="activity-img img-fluid"
                loading="lazy"
                src={`http://res.cloudinary.com/datacode/image/upload/v1687763436/vr2hbjnhli7y3iewspc4.png`}
                alt="avat ar"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeroSection;
