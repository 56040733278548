import React, { Component } from "react";
import { Link } from "react-router-dom";
import RightSideSection from "../../right_section/RightSideSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import RoadMapCard from "./RoadMapCard";
import FbLikeShare from "../../FbLikeShare";
import Technologies from "../../right_section/Technologies";
import LearningSideSection from "../../right_section/learning_sidesection/LearningSideSection";

class OOPs extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <div className='row'>
          <div className='col-1'></div>
          <div className='col-md-7 mt-4'>
            <h2
              className='text-center mb-4 display-5'
              style={{ color: "#072C71" }}
            >
              Find the Best OOPs Programming Courses & Tutorials
            </h2>
            <Link
              to='/home'
              className='badge mr-1'
              style={{ backgroundColor: "#06CB6C" }}
            >
              <FontAwesomeIcon icon={faHome} size='2x' color='#072C71' />
            </Link>
            <Link
              to='/tutorials'
              className='badge mr-1'
              style={{ backgroundColor: "#072C71" }}
            >
              <FontAwesomeIcon
                icon={faChalkboardTeacher}
                size='2x'
                color='#06CB6C'
              />
            </Link>
            <RoadMapCard
              imgsource='programming_blog.jpeg'
              title='Google Python Classes For Beginners'
              tag1='Free'
              link='google.com'
            />
          </div>

          <div className='col-md-4 mt-md-5 mt-3'>
            <div className='row'>
              <FbLikeShare />{" "}
            </div>
            <LearningSideSection language='c' />
            <Technologies />
            <RightSideSection />
          </div>
        </div>
      </>
    );
  }
}

export default OOPs;
