import img from "../../../../../../assets/images/AISummit/AI & Copilot summit Indore Posters  (LinkedIn Article Cover Image).png";
import sponsor_Img_1 from "../../../../../../assets/images/AISummit/1000200537_1.png";
import sponsor_Img_2 from "../../../../../../assets/images/AISummit/1000203136.png";
import Community_img_1 from "../../../../../../assets/images/AISummit/Medicaps.png";
import neevcloud from "../../../../../../assets/images/AISummit/neevcloud_logo_light.png";
import GDSCSVVV from "../../../../../../assets/images/AISummit/gdg svvv white.png";

export const CardData = [
  {
    head: "5",
    subhead: "Hours",
    pera: "Opportunity to meet, listen, and network with AI Experts.",
  },
  {
    head: "150+",
    subhead: "AI Enthuastics",
    pera: "(In person)",
  },
  {
    head: "3",
    subhead: "Speakers",
    pera: "Learn from some of the best AI developers speakers out there.",
  },
];
export const OurPartners = [
  {
    img: img,
  },
  {
    img: img,
  },
  {
    img: img,
  },
];
export const OurSponsor = [
  {
    img1: sponsor_Img_2,
  },
  {
    img1: sponsor_Img_1,
  },
];
export const OurVenue = [
  {
    img1: neevcloud,
  },
];
export const OurOrganizer = [
  {
    img2: sponsor_Img_1,
  },
  {
    img2: sponsor_Img_2,
  },
];

export const OurSpeakers = [
  {
    eventSpeakerImage:
      "http://res.cloudinary.com/datacode/image/upload/v1733496199/vsemeqpznryqdwonypdb.png",
    img3: "https://res.cloudinary.com/datacode/image/upload/v1732625215/qtzzwm2yxtzgnxsfdh34.png",
    name: "Nabajyoti Boruah",
    profession: "AI Expert at Synergetics",
    linkedinUrl: "",
    githubUrl: "",
    twitterUrl: "",
    impromptu:
      "Welcome to the event! I'm Nabajyoti Boruah, and I'm excited to share my insights on how Artificial Intelligence is revolutionizing industries across the globe. From automating processes to enhancing decision-making, AI is at the heart of innovation, and I can't wait to discuss how it will shape the future of business and technology.",
  },
  {
    img3: "https://res.cloudinary.com/datacode/image/upload/v1732625087/llj7frogotxekfl0272p.png",
    eventSpeakerImage:
      "http://res.cloudinary.com/datacode/image/upload/v1733492835/b1x5sts0dme5elh5f7wd.jpg",
    name: "Narendra Sen",
    profession: "Founder & CEO, NeevAI SuperCloud",
    linkedinUrl: "",
    githubUrl: "",
    twitterUrl: "",
    impromptu:
      "Hello, I’m Narendra Sen, Founder and CEO of NeevAI SuperCloud. During this session, I will take you through the transformative power of SuperClouds and how they can scale AI capabilities to new levels. Supercloud platforms are designed to drive innovation, and I look forward to showing you how they’re helping businesses achieve the impossible.",
  },
  {
    img3: "https://res.cloudinary.com/datacode/image/upload/v1732625330/iuxe0z8ebffcpt1fxju0.png",
    eventSpeakerImage:
      "http://res.cloudinary.com/datacode/image/upload/v1733493549/rmknf2itdzkznevdrntj.jpg",
    name: "Mrinal Jain",
    profession: "Community Head",
    linkedinUrl: "",
    githubUrl: "",
    twitterUrl: "",
    impromptu:
      "Greetings! I’m Mrinal Jain, and today I'll talk about the vital role of tech communities in fostering collaboration and driving innovation. It’s not just about the technology itself, but the community of creators, thinkers, and collaborators who work together to push the boundaries of what's possible.",
  },
  {
    img3: "https://res.cloudinary.com/datacode/image/upload/v1732625040/dfi6ftmzzlxxgxegdzni.png",
    eventSpeakerImage:
      "http://res.cloudinary.com/datacode/image/upload/v1733493640/ynraiiptgu2qpyuxzjex.jpg",
    name: "Radheshyam Dhakad",
    profession: "CTO, Neevcloud",
    linkedinUrl: "",
    githubUrl: "",
    twitterUrl: "",
    impromptu:
      "Hi, I’m Radheshyam Dhakad, the CTO at Neevcloud. In this session, I’ll share how cloud computing has become the backbone of modern technology. As a CTO, my focus is on creating scalable, secure, and innovative cloud solutions that empower businesses to transform and thrive in a digital-first world.",
  },
  {
    img3: "https://res.cloudinary.com/datacode/image/upload/v1732625174/mq031cpnj1pxv3oeppsb.png",
    eventSpeakerImage:
      "http://res.cloudinary.com/datacode/image/upload/v1733493936/jeerg60k7owel1pmnaax.jpg",
    name: "Noel Varghese",
    profession: "Head Cyber Security, Neevcloud",
    linkedinUrl: "",
    githubUrl: "",
    twitterUrl: "",
    impromptu:
      "Hello, I’m Noel Varghese, Head of Cybersecurity at Neevcloud. In today's session, I'll be highlighting the increasing importance of cybersecurity in a cloud-first world. As businesses continue to move to the cloud, securing data and infrastructure is paramount to maintaining trust and ensuring success.",
  },
  // {
  //   img3: "https://res.cloudinary.com/datacode/image/upload/v1732625393/h2q3iwrwofmbfnjrfkp5.png",
  //   eventSpeakerImage:
  //     "http://res.cloudinary.com/datacode/image/upload/v1733334824/cpeucc5bohc6s9dz9of5.png",
  //   name: "Aditya Jain",
  //   profession: "AI/ML Evangelist, Neevcloud",
  //   linkedinUrl: "",
  //   githubUrl: "",
  //   twitterUrl: "",
  //   impromptu:
  //     "Hi everyone, I’m Aditya Jain, and I’m passionate about AI and Machine Learning. In today’s session, I’ll discuss how these technologies are not just changing industries, but creating entirely new opportunities. Whether you're looking to automate tasks or gain deeper insights, AI/ML is the key to unlocking the next level of business performance.",
  // },
  {
    img3: "https://res.cloudinary.com/datacode/image/upload/v1732625393/h2q3iwrwofmbfnjrfkp5.png",
    eventSpeakerImage:
      "http://res.cloudinary.com/datacode/image/upload/v1733495613/ckvavvrszr5nsyg1mhly.jpg",
    name: "Akshay Mandliya",
    profession: "Full stack (MERN) Developer | Community Lead (datacode.in)",
    linkedinUrl: "",
    githubUrl: "",
    twitterUrl: "",
    impromptu:
      "Hi everyone, I’m Akshay Mandliya, a Full Stack (MERN) Developer and Community Lead at datacode.in. I’m deeply passionate about building innovative web applications and fostering a collaborative environment for tech enthusiasts. In today’s session, I’ll be exploring how the MERN stack empowers developers to create scalable, high-performance applications, and how our community is driving forward new ideas and learning.",
  },
];

export const OurSpeakerOrganizer = [
  {
    img4: "http://res.cloudinary.com/datacode/image/upload/v1731947593/d81ufdxokiu5rgdsrhwe.png",
    eventSpeakerImage:
      "http://res.cloudinary.com/datacode/image/upload/v1733334824/cpeucc5bohc6s9dz9of5.png",
    name: "Akshay Mandliya",
    profession: "Founder & CEO, Datacode",
    linkedinUrl: "",
    githubUrl: "",
    twitterUrl: "",
  },
  {
    img4: "http://res.cloudinary.com/datacode/image/upload/v1731947593/d81ufdxokiu5rgdsrhwe.png",
    eventSpeakerImage:
      "http://res.cloudinary.com/datacode/image/upload/v1733334824/cpeucc5bohc6s9dz9of5.png",
    name: "Akshay Mandliya",
    profession: "Founder & CEO, Datacode",
    linkedinUrl: "",
    githubUrl: "",
    twitterUrl: "",
  },
  {
    img4: "http://res.cloudinary.com/datacode/image/upload/v1731947593/d81ufdxokiu5rgdsrhwe.png",
    eventSpeakerImage:
      "http://res.cloudinary.com/datacode/image/upload/v1733334824/cpeucc5bohc6s9dz9of5.png",
    name: "Akshay Mandliya",
    profession: "Founder & CEO, Datacode",
    linkedinUrl: "",
    githubUrl: "",
    twitterUrl: "",
  },
];
export const Schedule = [
  {
    time: "9:00 - 10:00 AM",
    steps_1: " Registration & Check-In",
    steps_2: "Attendees arrive, register, and settle in.",
    Heading: "Designing for Design: API Decisions for Long-Term Success",
    img: "http://res.cloudinary.com/datacode/image/upload/v1731947593/d81ufdxokiu5rgdsrhwe.png",
    name: "Attendees arrive, register, and settle in.",
    talk: "Full Talk",
  },
  {
    time: "10:00 - 10:10 AM",
    steps_1: "Opening Remarks",
    steps_2: "Event introduction and brief welcome address.",
    Heading: "Designing for Design: API Decisions for Long-Term Success",
    img: "http://res.cloudinary.com/datacode/image/upload/v1731947593/d81ufdxokiu5rgdsrhwe.png",
    name: "Attendees arrive, register, and settle in.",
    talk: "Full Talk",
  },
  {
    time: "10:10 - 11:00 AM",
    steps_1: "Keynote Session",
    steps_2: "Opening keynote on AI’s role in development.",
    Heading: "Designing for Design: API Decisions for Long-Term Success",
    img: "http://res.cloudinary.com/datacode/image/upload/v1731947593/d81ufdxokiu5rgdsrhwe.png",
    name: "Attendees arrive, register, and settle in.",
    talk: "Full Talk",
  },

  {
    time: "11:00 - 11:50 AM",
    steps_1: "Talk Session 1",
    steps_2: "Gen AI.",
    Heading: "Designing for Design: API Decisions for Long-Term Success",
    img: "http://res.cloudinary.com/datacode/image/upload/v1731947593/d81ufdxokiu5rgdsrhwe.png",
    name: "Attendees arrive, register, and settle in.",
    talk: "Full Talk",
  },
  {
    time: "11:50 - 12:10 PM",
    steps_1: "Break + Fun Activity",
    steps_2: "Refreshments and a brief, engaging tech quiz.",
    Heading: "Designing for Design: API Decisions for Long-Term Success",
    img: "http://res.cloudinary.com/datacode/image/upload/v1731947593/d81ufdxokiu5rgdsrhwe.png",
    name: "Attendees arrive, register, and settle in.",
    talk: "Full Talk",
  },
  {
    time: "12:10 - 1:00 PM",
    steps_1: "Talk Session 1 Continue..",
    steps_2: "Practical AI applications in real-world projects.",
    Heading: "Designing for Design: API Decisions for Long-Term Success",
    img: "http://res.cloudinary.com/datacode/image/upload/v1731947593/d81ufdxokiu5rgdsrhwe.png",
    name: "Attendees arrive, register, and settle in.",
    talk: "Full Talk",
  },

  {
    time: "1:00 - 2:00 PM",
    steps_1: "Lunch Break",
    steps_2: "Networking lunch, giving attendees time to connect.",
    Heading: "Designing for Design: API Decisions for Long-Term Success",
    img: "http://res.cloudinary.com/datacode/image/upload/v1731947593/d81ufdxokiu5rgdsrhwe.png",
    name: "Attendees arrive, register, and settle in.",
    talk: "Full Talk",
  },
  {
    time: "2:00 - 3:30 PM",
    steps_1: "Networking Session",
    steps_2: "Casual Q&A, speaker engagement, and community building.",
    Heading: "Designing for Design: API Decisions for Long-Term Success",
    img: "http://res.cloudinary.com/datacode/image/upload/v1731947593/d81ufdxokiu5rgdsrhwe.png",
    name: "Attendees arrive, register, and settle in.",
    talk: "Full Talk",
  },
  {
    time: "3:30 - 4:00 PM",
    steps_1: "Closing Remarks & Takeaways",
    steps_2: "Final thoughts, key takeaways, and closing.",
    Heading: "Designing for Design: API Decisions for Long-Term Success",
    img: "http://res.cloudinary.com/datacode/image/upload/v1731947593/d81ufdxokiu5rgdsrhwe.png",
    name: "Attendees arrive, register, and settle in.",
    talk: "Full Talk",
  },
];

export const OurCommunityPartner = [
  {
    Community_Img: Community_img_1,
    communityName: "ACM-MU",
  },
  {
    Community_Img: GDSCSVVV,
    communityName: "Google Developer Student Club - SVVV",
  },
];
