import React from "react";

const OurCulture = () => {
  return (
    <div className="row mx-0 justify-content-center ourculture mt-5 pt-lg-4">
      <div className="col-11 col-md-10 col-lg-10">
        <h1 className="text-center">
          Our <span>Culture</span>
        </h1>
        <p
          className="text-justify text-lg-center text-md-center py-4 px-lg-5"
          data-aos="fade-up"
        >
          We follow the philosophy of owning our work and the results that come
          from them, we move fast and break things, we don’t stop, we never
          stop. We keep growing and never leave anyone behind. At the core we
          are a people first company and this is the compass we use to build
          each and every part of our culture.
        </p>
      </div>
    </div>
  );
};
export default OurCulture;
