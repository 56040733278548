export const SET_EVENT_REGISTRATION_LOADING = "SET_EVENT_REGISTRATION_LOADING";
export const SET_EVENT_DETAILS = "SET_EVENT_DETAILS";
export const GET_EVENT_DETAILS_LOADING = "GET_EVENT_DETAILS_LOADING";
export const GET_EVENTS_LIST_LOADING = "GET_EVENTS_LIST_LOADING";
export const SET_EVENTS_LIST = "SET_EVENTS_LIST";
export const SET_EVENTS_UPCOMING_LIST = "SET_EVENTS_UPCOMING_LIST";
export const SET_EVENTS_PAST_LIST = "SET_EVENTS_PAST_LIST";
export const SET_EVENTS_LIVE_LIST = "SET_EVENTS_LIVE_LIST";
export const SET_CLEAR_EVENTS_LIST = "SET_CLEAR_EVENTS_LIST";
export const SET_SEARCH_QUERY_OBJECT = "SET_SEARCH_QUERY_OBJECT";
export const SET_SEARCH_EVENTS_LIST = "SET_SEARCH_EVENTS_LIST";
export const SET_SEARCH_EVENTS_LIST_LOADING = "SET_SEARCH_EVENTS_LIST_LOADING";
export const SET_SHOW_SEARCH_RESULT_PAGE = "SET_SHOW_SEARCH_RESULT_PAGE";
