import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import DeleteTutorialModal from "./DeleteTutorialModal";
import { handleTextVisibility } from "../../../../../utils";
import {
  FaBookmark,
  FaHeart,
  FaRegBookmark,
  FaRegHeart,
} from "react-icons/fa6";

const TutorialListCard = ({
  id,
  title,
  tags,
  likeTutorial,
  unlikeTutorial,
  likesCount,
  userData,
  userData: { userName, email, myTutorialsLibrary },
  saveToLibrary,
  unsaveFromLibrary,
  tutorial,
  removeTutorial,
  handleEditTutorial,
  handleGetTutorialByUserName,
}) => {
  const history = useHistory();
  const [isLiked, setLiked] = useState();
  const [isSaved, setSaved] = useState();
  const [likeCount, setLikeCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setLiked(
      tutorial
        ? tutorial.likes && tutorial.likes.find((like) => like === userName)
        : false,
    );
    setLikeCount(tutorial ? tutorial.likes && tutorial.likes.length : 0);
    setSaved(
      userData &&
        userData.myTutorialsLibrary &&
        myTutorialsLibrary.find((lib) => lib === tutorial.id),
    );
  }, [tutorial, userData]);

  const handleLike = () => {
    if (!userName) {
      return history.push(`/login`);
    }
    likeTutorial({ id, userName }).then((res) => {
      if (res) {
        setLiked(res.data && res.data.likes.find((like) => like === userName));
        setLikeCount(res.data.likes.length);
        handleGetTutorialByUserName();
      }
    });
  };

  const handleUnLike = () => {
    unlikeTutorial({ id, userName }).then((res) => {
      if (res) {
        setLiked(res.data && res.data.likes.find((like) => like === userName));
        setLikeCount(res.data && res.data.likes.length);
        handleGetTutorialByUserName();
      }
    });
  };

  const handleSaveToLibrary = () => {
    if (!userName) {
      return history.push(`/login`);
    }
    const saveItem = tutorial.id;
    saveToLibrary({ saveItem, email }).then((res) => {
      if (res) {
        setSaved(true);
      }
    });
  };

  const handleUnsaveFromLibrary = () => {
    const saveItem = tutorial.id;
    unsaveFromLibrary({ saveItem, email }).then((res) => {
      if (res) {
        setSaved(false);
      }
    });
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleRemoveTutorial = () => {
    removeTutorial(id).then((res) => {
      if (res) {
        console.log(res, "res");
      }
    });
  };

  return (
    <>
      <div className='row m-0 tutorial-list-card border my-2'>
        <div className='col-md-4 col-4 '>
          <Link to={`/tutorial/${id}`}>
            <img
              src={tutorial.img}
              alt='avatar'
              loading='lazy'
              className=' img-fluid h-100 w-100'
            />
          </Link>
        </div>
        <div className='col-md-8 col-8'>
          <div className='row m-0 mb-2 text-right p-0'></div>
          <div className='tags-input row m-0 justify-content-between'>
            <div className='col-6 p-0'>
              <div className='tags-area border-0'>
                {tags.map((tag, index) => (
                  <div className='tag' key={index}>
                    <span>{tag}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className='col-6 p-0 text-right'>
              {likeCount}
              {isLiked ? (
                <span
                  onClick={() => handleUnLike()}
                  className='text-danger mx-2'
                >
                  <FaHeart />
                </span>
              ) : (
                <span onClick={() => handleLike()} className='mx-2'>
                  <FaRegHeart />
                </span>
              )}
              {isSaved ? (
                <span
                  onClick={() => handleUnsaveFromLibrary()}
                  className='mx-2'
                >
                  <FaRegBookmark />
                </span>
              ) : (
                <span onClick={() => handleSaveToLibrary()} className='mx-2'>
                  <FaBookmark />
                </span>
              )}
              <Link to={`/tutorial/${id}`}>
                <i className='fad fa-comments-alt voilet mx-2' />
              </Link>
              <i
                onClick={() => handleEditTutorial(id)}
                className='far fa-edit voilet mx-2'
              />
              <i
                onClick={() => setOpenModal(!openModal)}
                className='far fa-trash voilet mx-2'
              />
            </div>
          </div>
          <Link to={`/tutorial/${id}`}>
            <h6>{`${handleTextVisibility(tutorial && tutorial.title, 50)}`}</h6>
          </Link>
          <p>
            {`${handleTextVisibility(tutorial && tutorial.description, 200)}`}
          </p>
        </div>
      </div>
      <DeleteTutorialModal
        open={openModal}
        toggle={toggleModal}
        onSubmit={handleRemoveTutorial}
        submitButtonName={"Delete Tutorial"}
      />
    </>
  );
};

TutorialListCard.defaultProps = {
  userName: "",
  email: "",
  id: "",
  likesCount: 0,
  userData: {},
  tutorial: {},
  myTutorialsLibrary: [],
};

TutorialListCard.propTypes = {
  userName: PropTypes.string,
  id: PropTypes.string,
  likesCount: PropTypes.number,
  userData: PropTypes.object,
  tutorial: PropTypes.object,
  myTutorialsLibrary: PropTypes.array,
};

export default TutorialListCard;
