import { faCertificate, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import FbLikeShare from "../../../FbLikeShare";
import RightSideSection from "../../../right_section/RightSideSection";
import Technologies from "../../../right_section/Technologies";
import PostCard from "../../PostCard";
import { learningPlatforms } from "./helper";

class IndexLearningPlatforms extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <div className='row'>
          <div className='col-1'></div>
          <div className='col-md-8 mt-4'>
            <h1
              className='text-center mb-4 display-5 p-2 rounded'
              style={{ backgroundColor: "#30006b", color: "#ffffff" }}
            >
              Onine Learning Platforms for Best Learning
            </h1>
            <Link
              to='/home'
              className='badge mr-1'
              style={{ backgroundColor: "#06CB6C" }}
            >
              <FontAwesomeIcon icon={faHome} size='2x' color='#072C71' />
            </Link>
            <Link
              to='/index_learning_platforms'
              className='badge mr-1'
              style={{ backgroundColor: "#072C71" }}
            >
              <FontAwesomeIcon icon={faCertificate} size='2x' color='#06CB6C' />
            </Link>

            <div className='col-12 px-0'>
              <div className='row'>
                {learningPlatforms.map((resource) => {
                  return (
                    <div className='col-md-4 my-3'>
                      <PostCard
                        key={resource.id}
                        imgsource={resource.imgsource}
                        title={resource.title}
                        paragraph={resource.paragraph}
                        link={resource.link}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className='col-md-3 mt-md-5 mt-3'>
            <div className='row'>
              <FbLikeShare />{" "}
            </div>

            <RightSideSection />
            <Technologies />
          </div>
        </div>
      </>
    );
  }
}

export default IndexLearningPlatforms;
