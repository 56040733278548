import React from "react";
import { Link } from "react-router-dom";

export const ProgramUpdates = () => {
  return (
    <>
      <div className="row mx-0 border card-schadow rounded-lg mt-md-5">
        <div className="col-md-8 col-12 px-md-5 p-3">
          <h1 className="my-3">30 Days Of Code With Datacode</h1>
          <p className="text-dark">
            Whether you’re a novice or an expert at coding, come and put your
            brain to test in this battle.
            <br />
            Learn what problem solving is and get a high-level view of the
            programming landscape.
          </p>
          {/* <h6 className="font-weight-normal text-center">Batch Starting From: 01 June 2022</h6> */}
          {/* <h6 className="text-center">New Batch Starting Soon</h6> */}

          <div className="text-center">
            <Link to="/dayscode">
              <div className="btn enroll-btn mt-2 mb-4" type="button">
                <span>More Details</span>
              </div>
            </Link>
            <div className="">
              <h1 className="h4 text-danger">Applications are closed now!!!</h1>
            </div>
            {/* <div className="">
              <h1 className="h4 text-primary">Registrations are opening soon!!!</h1>
            </div> */}
          </div>
          <p className="text-dark px-md-5 text-center">
            Attempt one problem each day for 30 days to master your programming
            skills with datacode
          </p>
        </div>
        <div className="col-md-4 col-12">
          <img
            // height="320px"
            // width="320px"
            // src={'http://res.cloudinary.com/datacode/image/upload/v1625513918/jdb1wqwlenbv5itwgu2n.png'}
            src={require("../../../assets/images/gif/landing-days-code.gif")}
            alt="avatar"
            className="my-4 img-fluid"
          />
        </div>
      </div>
    </>
  );
};
