import React, { Component } from "react";
import { Link } from "react-router-dom";
class PostCard extends Component {
  state = {};
  render() {
    return (
      <>
        <div className='post-card'>
          <div className='card h-100 tech-card'>
            <div
              className='card-img-container position-relative overflow-hidden'
              style={{ height: "180px" }}
            >
              <Link to={this.props.link}>
                <img
                  src={this.props.imgsource}
                  // src={
                  //   imgsource.includes("cloudinary")
                  //     ? imgsource
                  //     : require("" + this.props.imgsource)
                  // }
                  alt={this.props.name}
                  className='card-img-top h-100 w-100 object-fit-contain'
                />
              </Link>
            </div>
            <div className='card-body'>
              <Link to={this.props.link}>
                <h3 className='card-title h4 fw-bold mb-3'>
                  {this.props.title.length > 25
                    ? this.props.title.slice(0, 20) + "..."
                    : this.props.title}
                </h3>
              </Link>
              <p className='card-text text-muted'>{this.props.paragraph}</p>
            </div>
            <div className='mt-auto px-3 mb-3 '>
              <Link to={this.props.link}>
                <button className='btn btn-gradient w-100 py-2 fw-semibold'>
                  Learn More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PostCard;
