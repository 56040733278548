import React from "react";
import { RiSpeakLine } from "react-icons/ri";
import { OurSpeakers } from "./Constants";

const AiSummitSpeakerWords = () => {
  return (
    <div className='row mx-0 justify-content-center  AiSummitSpeakersWords py-lg-5'>
      <div className='col-10  px-0'>
        <h1 className='text-center'>
          {" "}
          <RiSpeakLine className='p-0 mr-lg-2 mr-1' /> Insights from Our
          Speakers
        </h1>
        <div className='row mx-0 AiSummitOurSpeaker-card pt-5 d-flex align-items-center justify-content-center'>
          {OurSpeakers.map((item, i) => (
            <div className='col-12 col-md-11 mt-md-0 my-4 '>
              <div className='row mx-0 align-items-center'>
                <div
                  className={`col-lg-6 mb-4 mb-lg-0 px-0 mt-3 mt-md-0 ${
                    (i + 1) % 2 === 0 ? "order-2 " : "order-1"
                  }`}
                >
                  <div className=''>
                    <h3 className='fw-bold mb-2 text-white'>{item.name}</h3>
                    <p className='text-info mb-1'>{item.profession}</p>
                    {/* <p className='text-light opacity-75 mb-3'>{item.company}</p> */}
                    <h4 className='h5 fw-semibold text-white mb-3'>
                      {item.topic}
                    </h4>
                    <p className='text-light  text-justify'>{item.impromptu}</p>
                  </div>
                </div>
                <div
                  className={`col-lg-6 py-md-4 ${
                    (i + 1) % 2 === 0 ? "order-1 pl-0" : "order-2 pr-0"
                  }`}
                >
                  <img
                    src={item.eventSpeakerImage}
                    alt={item.name}
                    className=' w-100  rounded-lg'
                    height={300}
                    style={{ transition: "transform 0.3s" }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.transform = "scale(1.05)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.transform = "scale(1)")
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AiSummitSpeakerWords;
