import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const lifeData = [
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1731594935/xppjlevyqyvg9vtwqm06.jpg`,
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1731595321/tpvs8tsekgan3v060z4x.jpg`,
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1731682435/cct2xlswj1j9mjrga2a6.jpg`,
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1731682625/ws1quxpzbyajad3pfu4q.jpg`,
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1731682755/qnafr0qs2kzbzswclunn.jpg`,
  },
  // {
  //   img: `http://res.cloudinary.com/datacode/image/upload/v1731931349/owvdeqtwrwuim7xlqbvx.jpg`,
  // },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1731931549/aw3uavtonwgopyf8mceg.jpg`,
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1731931873/fmbvbdeshppp0lb8mktu.jpg`,
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1731933726/tyhnhajpebcoumdbwfv8.jpg`,
  },
];
const MomentsOfFun = () => {
  return (
    <div className="row mx-0 justify-content-center lifehere my-4 pt-lg-4 pb-lg-3">
      <div className="col-11 col-md-10 col-lg-10 px-md-5 px-0">
        <div className="row mx-0">
          <div className="col-12 col-md-4 col-lg-4 d-flex align-items-center pb-3 pb-lg-0">
            <div className="pr-md-3">
              <h2 className="text-center text-lg-left py-4">
                Moments of Fun, Learning and Success
              </h2>
              <p className="text-center text-lg-left text-md-left">
                Here’s what makes Datacode special
              </p>
            </div>
          </div>
          <div className="col-1"></div>
          <div className="col-12 col-md-7 col-lg-7 story-right">
            <div className="story-container">
              <Carousel
                additionalTransfrom={0}
                arrows={true}
                autoPlay
                autoPlaySpeed={2000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                partialVisible
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={true}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 1,
                    partialVisibilityGutter: 300,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 0,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 1,
                    partialVisibilityGutter: 150,
                  },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                {lifeData.map((items) => (
                  <div className="story-item">
                    <div className="d-flex gap-0">
                      <img
                        src={items.img}
                        className=""
                        height={280}
                        width={430}
                      />
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MomentsOfFun;
