const startDate = "Fri Dec 01 2023 00:00:00 GMT+0530 (India Standard Time)";
class DatacodeConfig {
  static baseURL = "http://www.localhost:5000/api";
  // static baseURL = "https://datacode-api.azurewebsites.net/api/";
  // static baseURL = "https://datacode-api-v2.azurewebsites.net/api";
  static baseURL = "https://datacode-api.vercel.app/api";
  static codeStartDate = startDate;
  // static batchStarted = new Date(startDate).getTime() <= new Date().getTime();
  static batchStarted = true;
  static currentBatches = ["2", "6"];
  static batchMentors = [
    {
      userName: "akshay@25",
      email: "akshaycse25@gmail.com",
    },
    {
      userName: "Dhanshree@15",
      email: "dhanshreenirgude80@gmail.com",
    },
    {
      userName: "rajanyadav.me@gmail.com",
      email: "rajanyadav.me@gmail.com",
    },
    {
      userName: "aalsi_baccha19",
      email: "jayesh.saini.sdbc@gmail.com",
    },
    {
      userName: "neeraj_42",
      email: "20193043neerajchaubey.it@gmail.com",
    },
    {
      userName: "chitrakshi",
      email: "chitrakshi2525@gmail.com",
    },
    {
      userName: "kusumita29",
      email: "29kusumita@gmail.com",
    },
    {
      userName: "Amisha@911",
      email: "amisharani911@gmail.com",
    },
  ];
}

export default DatacodeConfig;
