import React from "react";
import { FaRegCalendar } from "react-icons/fa6";
import { Schedule } from "./Constants";
const AiSummitSchedule = () => {
  return (
    <>
      <div className="row mx-0 justify-content-center AiSummitSchedule  py-5">
        <div className="col-lg-10 col-12  px-0">
          <h1 className="text-center py-4 align-items-center justify-content-center">
            <FaRegCalendar className="mr-lg-3 mr-2" />
            Schedule
          </h1>
          <div className="row mx-0 justify-content-center AiSummitSchedule-row-A">
            <div className="col-md-9 col-11 px-0 border rounded">
              {Schedule.map((item, i) => (
                <div key={i} className="py-4">
                  <div className="">
                    <div className="row mx-0 justify-content-center ">
                      <div className="col-11 px-0">
                        <div className="card  AiSummitSchedule-Card">
                          <div className="card-body text-left">
                            <h6 className="">{item.time}</h6>
                            <div className="">
                              <div className="AiSummitSchedule-Card-head">
                                <h4 className="">{item.steps_1}</h4>
                                <p>{item.steps_2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AiSummitSchedule;
