import { faChalkboardTeacher, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import RightSideSection from "../../../right_section/RightSideSection";
import PostCard from "../../PostCard";
import InterviewQuestionCardCarousel from "./InterviewQuestionCardCarousel";

import { Helmet } from "react-helmet";
import Technologies from "../../../right_section/Technologies";
import { interviewQuestionDetails } from "./helper";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
class IndexInterviewQuestions extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const description =
      "Seeking for Hiring?Confuse with preparation?Don’t worry we are here with the best material which makes your luck good ,it’s time to reserve your seat in the tech world.";
    const title = "Interview Preparation Questions | Datacode.in";
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta
            data-react-helmet='true'
            name='description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            http-equiv='cleartype'
            content='on'
          ></meta>
          <meta
            data-react-helmet='true'
            name='apple-mobile-web-app-capable'
            content='yes'
          ></meta>
          <meta
            data-react-helmet='true'
            name='robots'
            content='max-snippet:-1, max-image-preview:large, max-video-preview:-1'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:locale'
            content='en_US'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:type'
            content='website'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:title'
            content={title}
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:url'
            content='https://www.datacode.in/'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:site_name'
            content='Datacode.in'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:secure_url'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:width'
            content='400'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:height'
            content='400'
          ></meta>
          <meta
            data-react-helmet='true'
            name='keywords'
            content='programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:card'
            content='summary_large_image'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:title'
            content={title}
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:site'
            content='@datacode_in'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:creator'
            content='@datacode_in'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:image'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <link
            data-react-helmet='true'
            rel='shortlink'
            href='https://www.datacodoe.in/'
          ></link>
          <link
            data-react-helmet='true'
            rel='dns-prefetch'
            href='//www.datacode.in'
          ></link>
          <link
            data-react-helmet='true'
            rel='dns-prefetch'
            href='//s.w.org'
          ></link>
          <link
            data-react-helmet='true'
            rel='alternate'
            type='application/rss+xml'
            title='Datacode'
            href='https://www.datacode.in/'
          ></link>
          <link
            data-react-helmet='true'
            rel='canonical'
            href='https://www.datacode.in/'
          ></link>
        </Helmet>
        <div className='row m-0 px-3 justify-content-center index-technologies'>
          <div className='col-1'></div>
          <div className='col-md-8  pl-0'>
            <div className='col-12 px-0 mb-5'>
              <p className='nav-link pt-2 my-3  d-flex align-items-center px-0 '>
                <Link to='/' className=' mx-1'>
                  Home
                </Link>
                <MdOutlineKeyboardArrowRight />
                <Link to='/index_interview_questioins' className='mx-1'>
                  Interview Questions
                </Link>
              </p>
            </div>
            <div className='row mx-0'>
              <div className='col-8 px-0'>
                <h1 className=''>
                  <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview
                  Questions
                </h1>
                <p className='text-justify'>
                  Seeking for Hiring? Confuse with preparation?
                  <br />
                  Don’t worry we are here with the best material which makes
                  your luck good ,it’s time to reserve your seat in the tech
                  world.
                </p>
              </div>
              <div className='col-4'></div>
            </div>
            <div className='col-12 px-0'>
              <div className='row'>
                {interviewQuestionDetails.map((resource) => {
                  return (
                    <div className='col-md-4 my-3' key={resource.title}>
                      <PostCard
                        imgsource={resource.imgsource}
                        title={resource.title}
                        paragraph={resource.paragraph}
                        link={"/interview_question" + resource.link}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <h4 className='text-secondary mt-3'>
              For More Placment Preparation
            </h4>
            <InterviewQuestionCardCarousel />
          </div>
          <div className='col-md-3 px-0 pl-2'>
            <RightSideSection />
            <Technologies />
          </div>
        </div>
      </>
    );
  }
}

export default IndexInterviewQuestions;
