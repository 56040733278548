import React from "react";

const CodePenBlockPreview = ({ item }) => {
  const codepen_parser = (item) => {
    var regExp = /https?:\/\/codepen\.io\/[^\/]+\/pen\/([^\/]+)/;
    var match = item && item && item.match(regExp);
    return match && match[1] ? match[1] : false;
  };

  const penId = codepen_parser(item);

  return (
    <>
      <div className='my-5'>
        <h4 className='text-center mb-2 blog-headings'>CodePen Block</h4>
        {penId ? (
          <div className='codepen-responsive '>
            <iframe
              style={{ width: "100%", height: "400px" }}
              // scrolling='no'
              title='Embedded CodePen'
              src={`https://codepen.io/${item.split("/")[3]}/embed/${penId}`}
              loading='lazy'
              allowTransparency='true'
              allowFullScreen='true'
            />
          </div>
        ) : (
          <p>Invalid CodePen URL</p>
        )}
      </div>
    </>
  );
};

export default CodePenBlockPreview;
