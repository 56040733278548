import React, { Component } from "react";
import { Link } from "react-router-dom";
import { techIcon } from "../main_component/tutorials/components/Tutorials/helpers";

class Technologies extends Component {
  state = {};
  render() {
    return (
      <>
        <div className='card card-shadow text-center mt-5 mb-4'>
          <h6
            className=' mt-2 text-center mb-0 pb-2 border-bottom'
            style={{ color: "#072C71" }}
          >
            More Technologies
          </h6>
          <div className='row mx-0'>
            {techIcon.map((item, i) => (
              <>
                <Link
                  className='col-12 col-md-6 p-2 px-0 text-left tech-card d-flex align-items-center border-bottom'
                  key={i}
                  to={`/tutorials/${item.technology}`}
                >
                  <img
                    height='35px'
                    width='35px'
                    src={require(`../../assets/images/svg/${item.svgSource}`)}
                    alt='avatar'
                    loading='lazy'
                    className='mr-md-1 mr-4 '
                  />
                  <h6 className='mb-0 d-none d-md-block'>
                    {item.title.length > 9
                      ? item.title.slice(0, 9) + "..."
                      : item.title}
                  </h6>
                  <h6 className='mb-0 d-block d-md-none'>{item.title}</h6>
                </Link>
              </>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default Technologies;
