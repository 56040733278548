import {
  faAward,
  faChalkboardTeacher,
  faCheckSquare,
  faCode,
  faFlask,
  faHatCowboy,
  faHome,
  faNewspaper,
  faSignOutAlt,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { Link, useHistory } from "react-router-dom";
import { UncontrolledCollapse } from "reactstrap";

import DatacodeConfig from "../../../../core/config";

const SideDrawer = ({
  backDropClickHandler,
  show,
  currentUser,
  logoutUser,
  userProfileLoading,
}) => {
  const history = useHistory();
  const batchMentors = DatacodeConfig.batchMentors || [];

  const isAdmin =
    currentUser &&
    currentUser.userName === process.env.REACT_APP_ADMIN_USERNAME &&
    currentUser.email === process.env.REACT_APP_ADMIN_EMAIL;

  let drawerClasses = "side-drawer";
  if (show) {
    drawerClasses = "row m-0 side-drawer open";
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logoutUser();
    history.push("/");
  };

  const authMentorGuard = () => {
    var localUser = JSON.parse(localStorage.getItem("userDetails"));
    return (
      localUser &&
      batchMentors.find(
        (x) => x.email === localUser.email && x.userName === localUser.userName,
      )
    );
  };

  return (
    <>
      <div className={drawerClasses}>
        <div className='col-12 p-0'>
          <div className='row m-0 mt-md-0 mt-4 side-header'>
            <div className='col-12'>
              {currentUser && currentUser.userName ? (
                <>
                  <div className='header-profile'>
                    <div className='mt-md-3 mt-0 d-flex justify-content-between'>
                      <Link
                        onClick={() => backDropClickHandler()}
                        to={`/my_profile/${currentUser.userName}`}
                      >
                        {userProfileLoading ? (
                          <div className='rounded-circle header-profile-img'>
                            <Loader
                              type='Audio'
                              color='#30006d'
                              height={40}
                              width={40}
                              timeout={8000} //3 secs
                            />
                          </div>
                        ) : (
                          <img
                            className='rounded-circle header-profile-img'
                            height='40'
                            width='40'
                            loading='lazy'
                            src={
                              currentUser && currentUser.imgUrl
                                ? currentUser.imgUrl
                                : require(`../../../../assets/images/svg/monolog.svg`)
                            }
                            alt='avatar'
                          />
                        )}
                      </Link>
                      <div className='d-none d-sm-block'>
                        <i
                          className='nav-cross fas fa-times mx-md-3'
                          onClick={() => backDropClickHandler()}
                        />
                      </div>
                    </div>
                    <div className='d-flex align-items-center mt-2'>
                      <h2>
                        {`Hello! ${currentUser
                            ? currentUser.firstName
                              ? currentUser.firstName
                              : currentUser.userName
                            : "Learner"
                          }`}
                      </h2>
                      <i
                        onClick={() => toggle()}
                        className={`far ${isOpen ? "fa-angle-up" : "fa-angle-down"
                          } ml-2`}
                        id='toggler'
                      />
                    </div>
                    <UncontrolledCollapse toggler='#toggler'>
                      <div className='row m-0 mt-3 '>
                        <div className='col-4 pl-0 pr-2 text-center'>
                          <Link
                            onClick={() => backDropClickHandler()}
                            to={`/my_profile/${currentUser.userName}`}
                          >
                            <i className='fas fa-user' />
                            <p>My Profile</p>
                          </Link>
                        </div>
                        <div className='col-4 text-center'>
                          <Link
                            onClick={() => backDropClickHandler()}
                            to={`/my_library`}
                          >
                            <i className='fas fa-window-restore' />
                            <p>My Library</p>
                          </Link>
                        </div>
                        <div className='col-4 text-center'>
                          <Link
                            to='/edit_profile'
                            onClick={() => backDropClickHandler()}
                          >
                            <i className='far fa-user-edit' />
                            <p>Edit Profile</p>
                          </Link>
                        </div>
                      </div>
                    </UncontrolledCollapse>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <h2 className='mt-2'>Hello! Learner</h2>
                    <p>Start your learning with Community</p>

                    <Link to='/signup' onClick={() => backDropClickHandler()}>
                      <div className='btn join-us mb-3'>Join Us</div>
                    </Link>
                  </div>
                </>
              )}
            </div>
            {/* <div className="col-2 text-right">
              <i
                className="nav-cross fal fa-times mx-md-3"
                onClick={() => backDropClickHandler()}
              />
            </div> */}
          </div>
          <div className='row m-0 ul-list mt-4'>
            <ul className='m-0 p-0'>
              {isAdmin && (
                <Link to='/admin' onClick={() => backDropClickHandler()}>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faHatCowboy} className='mr-2' />{" "}
                      Admin Panel
                    </span>
                  </li>
                </Link>
              )}
              {authMentorGuard() && !isAdmin && (
                <Link
                  to='/admin/dayscode/problems'
                  onClick={() => backDropClickHandler()}
                >
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faHatCowboy} className='mr-2' />{" "}
                      Mentor Panel
                    </span>
                  </li>
                </Link>
              )}
              <Link to='/dayscode' onClick={() => backDropClickHandler()}>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCode} className='mr-2' /> 30 Days
                    of Code
                  </span>
                </li>
              </Link>
              <Link to='/home' onClick={() => backDropClickHandler()}>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faHome} className='mr-2' /> Home
                  </span>
                </li>
              </Link>

              <Link
                to='/tutorials'
                onClick={() => backDropClickHandler()}
              >
                <li>
                  <span>
                    <FontAwesomeIcon
                      icon={faChalkboardTeacher}
                      className='mr-2'
                    />{" "}
                    Tutorials/Courses
                  </span>
                </li>
              </Link>

              <Link
                to='/index_training_certification'
                onClick={() => backDropClickHandler()}
              >
                <li>
                  <span>
                    <FontAwesomeIcon icon={faAward} className='mr-2' />{" "}
                    Training/Certification
                  </span>
                </li>
              </Link>
              <Link
                to='/index_interview_questioins'
                onClick={() => backDropClickHandler()}
              >
                <li>
                  <span>
                    <FontAwesomeIcon icon={faUserTie} className='mr-2' />{" "}
                    Interview Preparation
                  </span>
                </li>
              </Link>
              <Link
                to='/index_quize_tests'
                onClick={() => backDropClickHandler()}
              >
                <li>
                  <span>
                    <FontAwesomeIcon icon={faFlask} className='mr-2' /> Quiz
                    Tests
                  </span>
                </li>
              </Link>
              <Link to='/index_mcqs' onClick={() => backDropClickHandler()}>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCheckSquare} className='mr-2' />{" "}
                    Programming MCQs
                  </span>
                </li>
              </Link>
              <Link
                to='/index_programming_questions'
                onClick={() => backDropClickHandler()}
              >
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCode} className='mr-2' /> Practice
                    Code Problem
                  </span>
                </li>
              </Link>
              <Link to='/index_articles' onClick={() => backDropClickHandler()}>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faNewspaper} className='mr-2' />{" "}
                    Blogs
                  </span>
                </li>
              </Link>
              {currentUser && currentUser.email && (
                <li onClick={() => handleLogout()}>
                  <span>
                    <FontAwesomeIcon icon={faSignOutAlt} className='mr-2' /> Log
                    out
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

SideDrawer.defaultProps = {
  email: "",
  currentUser: {},
};

SideDrawer.propTypes = {
  email: PropTypes.string,
  currentUser: PropTypes.object,
};

export default SideDrawer;
