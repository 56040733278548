import React from "react";
import { useEffect, useState } from "react";

const AiSummitCounter = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Set your target date and time here (for example, 7 days from now)
    const targetDate = new Date("2024-12-01T00:00:00").getTime();

    const countdown = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance > 0) {
        setTimeLeft({
          days: Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((distance % (1000 * 60)) / 1000),
        });
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  return (
    <>
      <div className="row mx-0 justify-content-center my-lg-0 my-md-0 py-lg-0  AiSummitCounter">
        <div className="col-lg-8 col-12 px-md-0 px-2">
          <div className="row mx-0 text-dark justify-content-center">
            <div className="col-12 d-flex p-2 justify-content-md-center justify-content-around gap-lg-2 gap-0 align-items-center AiSummitCounter-section">
              <div className="text-center">
                <h1 className="text-24">{timeLeft.days}</h1>
                <h3 className="text-20 ">DAYS</h3>
              </div>
              <div className="d-flex align-items-center">
                <h1 className="count-down-seperator px-lg-2 px-md-2 px-1">:</h1>
              </div>

              <div className="text-center">
                <h1 className="text-24 ">{timeLeft.hours}</h1>
                <h3 className="text-20 ">HOURS</h3>
              </div>
              <div className="d-flex align-items-center">
                <h1 className="count-down-seperator px-lg-2 px-md-2 px-1">:</h1>
              </div>

              <div className="text-center">
                <h1 className="text-24 ">{timeLeft.minutes}</h1>
                <h3 className="text-20 ">MINUTES</h3>
              </div>
              <div className="d-flex align-items-center">
                <h1 className="count-down-seperator px-lg-2 px-md-2 px-1">:</h1>
              </div>
              <div className="text-center">
                <h1 className="text-24 ">{timeLeft.seconds}</h1>
                <h3 className="text-20 ">SECONDS</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AiSummitCounter;
