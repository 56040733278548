import React from "react";
import { FaRegHandshake } from "react-icons/fa6";
import { OurCommunityPartner } from "./Constants";

export default function AiSummitOurCommunityPartner() {
  return (
    <div>
      <div className="row mx-0 justify-content-center AiSummitOurCommunityPartner py-5">
        <div className="col-10 px-0">
          <h1 className="text-center">
            {" "}
            <FaRegHandshake className="mr-lg-3 mr-2" /> Our Community Partners
          </h1>
          <div className="row mx-0 pt-5">
            <div className="col-12">
              <div className="d-flex flex-wrap gap-5 justify-content-center align-items-center">
                {OurCommunityPartner.map((item, i) => (
                  <div key={i} className="">
                    <img
                      src={item.Community_Img}
                      alt="img"
                      width={200}
                    />
                    <p className="text-white mt-2">{item.communityName}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
