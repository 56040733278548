export const mcqQuestion = [
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157858/k0u4vhphhxvpvaigp4i4.jpg",
    title: "Python MCQs Questions",
    paragraph:
      " Python questions and answers focuses on all areas of Python subject covering most topics in Python. These topics are chosen from a collection of most authoritative and best reference books on Python. ",
    link: "/index_python_mcqs",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157885/wcjvtr3uf4exwzaxgnxj.jpg",
    title: "C Programming MCQs Questions",
    paragraph:
      "C questions and answers focuses on all areas of C subject covering most topics in C. These topics are chosen from a collection of most authoritative and best reference books on C. ",
    link: "/index_c_mcqs",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157910/eadmvstw2ango8pnwsiv.jpg",
    title: "C++ MCQs Questions",
    paragraph:
      "C++ questions and answers focuses on all areas of C++ subject covering most topics in C++. These topics are chosen from a collection of most authoritative and best reference books on C++. ",
    link: "/index_cpp_mcqs",
  },

  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157975/kr8yum1motjudoewtavh.jpg",
    title: "Java MCQs Questions",
    paragraph:
      "Java questions and answers focuses on all areas of Java subject covering most topics in Java. These topics are chosen from a collection of most authoritative and best reference books on Java. ",
    link: "/index_java_mcqs",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731158004/bkb6y9mvjw0njxwdhxf8.jpg",
    title: "JavaScript MCQs Questions",
    paragraph:
      "JavaScript questions and answers focuses on all areas of JavaScript subject covering most topics in JavaScript. These topics are chosen from a collection of most authoritative and best reference books on JavaScript. ",
    link: "/index_js_mcqs",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731158036/omgnyhwpygdzywzewtlv.jpg",
    title: "DBMS MCQs Questions",
    paragraph:
      "DBMS questions and answers focuses on all areas of DBMS subject covering most topics in DBMS. These topics are chosen from a collection of most authoritative and best reference books on DBMS.",
    link: "/index_dbms_mcqs",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731161367/barujpunipesehx7i0of.jpg",
    title: "HTML MCQs Questions",
    paragraph:
      "HTML questions and answers focuses on all areas of HTML subject covering most topics in HTML. These topics are chosen from a collection of most authoritative and best reference books on HTML.",
    link: "/index_html_mcqs",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731161420/xc3vrpixqbtb3mnhti0s.jpg",
    title: "CSS MCQs Questions",
    paragraph:
      "CSS questions and answers focuses on all areas of CSS subject covering most topics in CSS. These topics are chosen from a collection of most authoritative and best reference books on CSS.",
    link: "/index_css_mcqs",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731161445/i3ow0r4qpmndkrwhacgh.jpg",
    title: "BootStrap MCQs Questions",
    paragraph:
      "Bootstrap questions and answers focuses on all areas of Bootstrap subject covering most topics in Bootstrap. These topics are chosen from a collection of most authoritative and best reference books on Bootstrap.",
    link: "/index_bootstrap_mcqs",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731161471/vqzda0mqpgght5trtznl.jpg",
    title: "React MCQs Questions",
    paragraph:
      "React questions and answers focuses on all areas of React subject covering most topics in React. These topics are chosen from a collection of most authoritative and best reference books on React.",
    link: "/index_react_mcqs",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731168857/ogtgs5fg21l3t0bwmzvg.jpg",
    title: "Django MCQs Questions",
    paragraph:
      "Django questions and answers focuses on all areas of Django subject covering most topics in DJango. These topics are chosen from a collection of most authoritative and best reference books on Django.",
    link: "/index_django_mcqs",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731168889/hkludlrszla9luh2bpsn.jpg",
    title: "Git MCQs Questions",
    paragraph:
      "Git questions and answers focuses on all areas of GIt covering most topics. These topics are chosen from a collection of most authoritative and best reference on Git.",
    link: "/index_git_mcqs",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731168913/lmfsg8hntqiupp6tseb3.jpg",
    title: "Android MCQs Questions",
    paragraph:
      "Android questions and answers focuses on all areas of Android subject covering most topics in Android. These topics are chosen from a collection of most authoritative and best reference books on Android.",
    link: "/index_android_mcqs",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731168950/cxpxowzru4f0gniti5pb.jpg",
    title: "SQL MCQs Questions",
    paragraph:
      "SQL questions and answers focuses on all areas of SQL subject covering most topics in SQL. These topics are chosen from a collection of most authoritative and best reference books on SQL.",
    link: "/index_sql_mcqs",
  },
  {
    imgsource:
      "https://res.cloudinary.com/datacode/image/upload/v1731168970/m8j8ha7avbfjj7rm621w.jpg",
    title: "DS MCQs Questions",
    paragraph:
      "DS questions and answers focuses on all areas of DS subject covering most topics in DS. These topics are chosen from a collection of most authoritative and best reference books on DS.",
    link: "/index_ds_mcqs",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731169003/sqox9cmnjpexsj6atsgi.jpg",
    title: "OOPs MCQs Questions",
    paragraph:
      "OOPS questions and answers focuses on all areas of OOPS subject covering most topics in OOPS. These topics are chosen from a collection of most authoritative and best reference books on OOPS.",
    link: "/index_oops_mcqs",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731169030/fsrwjbsj3r5iwcqhnfat.jpg",
    title: "Angular MCQs Coming Soon",
    paragraph:
      "Angular questions and answers focuses on all areas of Angular subject covering most topics in Angular. These topics are chosen from a collection of most authoritative and best reference books on Angular.",
    link: "/index_angular_mcqs",
  },
];
