import React from "react";
import { Link } from "react-router-dom";

const SuperAdmin = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="row mx-0">
          <div className="col-12">
            <h1>Super Admin</h1>
          </div>
        </div>
        <div className="row m-0 d-flex justify-content-center">
          <Link to="/admin/users" className="col-md-4 col-12">
            <div className=" border card-schadow p-4">
              <h4>
                <i className="fa fa-users-cog mr-2" />
                All Users List
              </h4>
            </div>
          </Link>
          <Link to="/admin/dayscode/problems" className="col-md-4 col-12">
            <div className="border card-schadow p-4">
              <h4>
                <i className="fa fa-code mr-2" />
                30 Days Code
              </h4>
            </div>
          </Link>
          <Link to="/admin/club/applications" className="col-md-4 col-12">
            <div className="border card-schadow p-4">
              <h4>
                <i className="fa fa-code mr-2" />
                Student Learning Club
              </h4>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SuperAdmin;
