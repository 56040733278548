import React, { useRef, useState } from "react";

import html2canvas from "html2canvas";
import heroImg from "../../../assets/images/svg/wittyhacks/wittyhacks-team.png";
import datcode_logo from "../../../assets/images/svg/wittyhacks/datacode_logo.png";
import { LuCopy } from "react-icons/lu";
import { IoMdDownload } from "react-icons/io";

export const FrameSection = ({ frameImg, para1, para2, tags }) => {
  const [img, setImg] = useState();
  const [uploadFile, setUploadFile] = useState(true);

  const handleFrame = (e) => {
    console.log(e.target.files);
    const selectedFile = e.target.files;
    if (selectedFile) {
      setUploadFile(false);
    }
    setImg(URL.createObjectURL(e.target.files[0]));
  };

  const divRef = useRef(null);
  const downloadDiv = async () => {
    const divToDownload = divRef.current;
    if (!divToDownload) {
      console.error("Nothing to download");
      return;
    }
    try {
      const canvas = await html2canvas(divToDownload, {
        allowTaint: true,
        useCORS: true,
        scale: 4,
      });
      const dataUrl = canvas.toDataURL("image/jpg");

      const downloadLink = document.createElement("a");
      downloadLink.href = dataUrl;
      downloadLink.download = "AI_Summit_Indore_frame.png";

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error capturing div content:", error);
    }
  };

  return (
    <>
      <div className='frame-section d-flex padding-x padding-y mt-5 main'>
        <div className='frame-download' id='frame' ref={divRef}>
          <img src={frameImg} alt='' className='frame-img' />
          <img src={img} alt='' className='user-frame-img' />
        </div>

        <div className='d-flex flex-column mx-5 digital-badge  p-3 order-1'>
          <div>
            <h1 className='mt-5'>Digital Badge</h1>
            <p>{para1}</p>
            <p>{para2}</p>
            <p>
              {tags &&
                tags.map((tag) => <span className='hashtag'>{tag}</span>)}
            </p>
          </div>
          <div>
            <div className='d-flex mt-2 form-elements'>
              <label htmlFor='inputFile' className='mr-3'>
                Upload Your Photo
              </label>
              <input id='inputFile' type='file' onChange={handleFrame} />
              <button
                className='btn mr-3'
                id='downloadBtn'
                onClick={downloadDiv}
                disabled={uploadFile}
              >
                Download
              </button>
            </div>
            <div className='caption-layout mt-4 order-2'>
              <Caption />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const HeroSection = () => {
  return (
    <>
      <div className='hero-section padding-x mt-md-5 mt-3'>
        <img src={heroImg} alt='' />
      </div>
    </>
  );
};

export const DetailsPanel = () => {
  return (
    <>
      <div className='details-panel padding-x mt-5'>
        <span>What is</span>
        <h1 className='d-flex justify-content-center'>WITTYHACKS</h1>
        <div className='d-flex justify-content-center'>
          <div className='gradient-line mt-2'></div>
        </div>

        <p className='text-center mt-5'>
          Wittyhacks prides itself on being Central India’s biggest community
          hackathon. Since its inception in 2018, Wittyhacks has been a platform
          where technology leaders and the brightest minds come together to
          collaborate on building tools that solve real problems. With big
          breakthroughs in the past edition, our team is thrilled to host its
          third in the Cleanest City of India, Indore. We are all set to make
          Wittyhacks a bigger success than any of the previous iterations
        </p>

        <span className='mt-5'>Presented By</span>
        <div className='d-flex justify-content-center logo-shadow my-4 align-items-center '>
          <img src={datcode_logo} alt='Company Logo' />
        </div>
        <div className='d-flex justify-content-center   '>
          {/* <a href="https://wittyhacks.in/" className="btn my-3">
            VISIT WITTYHACKS
          </a> */}
          <button
            className=' wittyhack-button'
            onClick={() => {
              window.open("https://wittyhacks.in/", "_blank");
            }}
          >
            VISIT WITTYHACKS
          </button>
        </div>
      </div>
    </>
  );
};

export const Caption = () => {
  const handleCopy = () => {
    const captionDetail = document.getElementById("caption-detail");
    const copiedData = captionDetail.innerHTML;
    console.log(copiedData);
    navigator.clipboard.writeText(copiedData);
  };
  return (
    <>
      <h3>Caption</h3>
      <div className='d-flex caption px-2 py-md-4 py-2 mt-3'>
        <div className='pr-3' id='caption-detail'>
          🎉 I’m excited to be a part of the AI & Copilot Summit Indore
          happening on December 1, 2024! 🚀 💡 Let’s explore the future of AI
          and Copilot technology together. Join me and grab your spot now!
          #AIandCopilotSummit #datacode #TechCommunity #IndoreEvents
        </div>
        <span onClick={handleCopy} className='copy-clipboard'>
          <LuCopy />
        </span>
      </div>
    </>
  );
};

export const DesktopWallpaper = ({ imgUrlDesktop }) => {
  return (
    <>
      <div className='desktop-wallpaper pb-5 pt-1'>
        <a href={imgUrlDesktop} download={imgUrlDesktop}>
          <div
            className='image-set desktop-image mx-2 d-flex justify-content-end align-items-end'
            style={{ backgroundImage: `url(${imgUrlDesktop})` }}
          >
            <span className='download-wall m-3'>
              <IoMdDownload />
            </span>
          </div>
        </a>
      </div>
    </>
  );
};

// export const ZoomBackground = ({ zoomBackground }) => {
//   return (
//     <>
//       <div className="desktop-wallpaper pb-5 pt-1">
//         <a href={zoomBackground} download={zoomBackground}>
//           <div
//             className="image-set mx-2 d-flex justify-content-end align-items-end zoom-image"
//             style={{ backgroundImage: `url(${zoomBackground})` }}
//           >
//             <span className="download-wall m-3">
//               <IoMdDownload />
//             </span>
//           </div>
//         </a>
//       </div>
//     </>
//   );
// };

export const MobileWallpaper = ({ imgUrlMobile }) => {
  return (
    <>
      <div className='desktop-wallpaper pb-5 pt-1'>
        <a href={imgUrlMobile} download={imgUrlMobile}>
          <div
            className='image-set mx-2 d-flex justify-content-end align-items-end mobile-image'
            style={{ backgroundImage: `url(${imgUrlMobile})` }}
          >
            <span className='download-wall m-3'>
              <IoMdDownload />
            </span>
          </div>
        </a>
      </div>
    </>
  );
};

export const SocialStoryTheme = ({ imgUrlSocial }) => {
  return (
    <>
      <div className='desktop-wallpaper pb-5 pt-1'>
        <a href={imgUrlSocial} download={imgUrlSocial}>
          <div
            className='image-set mx-2 d-flex justify-content-end align-items-end mobile-image'
            style={{ backgroundImage: `url(${imgUrlSocial})` }}
          >
            <span className='download-wall m-3'>
              <IoMdDownload />
            </span>
          </div>
        </a>
      </div>
    </>
  );
};
