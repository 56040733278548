export const SHOW_FOOTER = "SHOW_FOOTER";
export const HIDE_FOOTER = "HIDE_FOOTER";
export const SHOW_ADD_TUTORIAL = "SHOW_ADD_TUTORIAL";
export const HIDE_ADD_TUTORIAL = "HIDE_ADD_TUTORIAL";
export const SET_SUBSCRIBE_LOADING = "SET_SUBSCRIBE_LOADING";
export const SET_SUBSCRIBE_NEWS_LETTER = "SET_SUBSCRIBE_NEWS_LETTER";
export const SHOW_ADD_TESTIMONIAL = "SHOW_ADD_TESTIMONIAL";
export const SET_ADD_TESTIMONIAL = "SET_ADD_TESTIMONIAL";
export const SET_EDIT_TESTIMONIAL = "SET_EDIT_TESTIMONIAL";
export const SET_ADD_TESTIMONIAL_LOADING = "SET_ADD_TESTIMONIAL_LOADING";
export const SET_TESTIMONIALS = "SET_TESTIMONIALS";
export const HIDE_COMPLETE_FOOTER = "HIDE_COMPLETE_FOOTER";
export const HIDE_COMPLETE_HEADER = "HIDE_COMPLETE_HEADER";
export const SET_ADD_CONTACT_MESSAGE_LOADING =
  "SET_ADD_CONTACT_MESSAGE_LOADING";
