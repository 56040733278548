export const ArticlesResources = [
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157170/znri1uoltjiuj5xxlqmr.jpg",
    title: "Learning Platforms",
    paragraph: "Curious minds do adventures- LEARN GROW REPEAT",
    link: "/index_learning_platforms",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157219/ocekzr8xbepps94jmrhz.jpg",
    title: "Programming Languages Blogs",
    paragraph:
      "Small coding steps lead to large destinations~Time to enjoy the errors!",
    link: "/prog_lan_blog",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157170/znri1uoltjiuj5xxlqmr.jpg",
    title: "Web Design Blogs",
    paragraph: "Art of Technology,creation starts from designing/styling.",
    link: "/webdesign_blog",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157298/rg6whmbdh3ewvhnhety2.jpg",
    title: "DBMS Blogs",
    paragraph:
      "Let’s wake up our data  by asking a query for building relationships.",
    link: "/dbms_blog",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157325/lbdjpgwmtikkggsxxssz.jpg",
    title: "Documentation Blogs",
    paragraph:
      "Stealing source code gives you pleasure,but not the succes-#SELFCODE..",
    link: "/documentation_blog",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157348/vuj9wiugwee2y2hdjsqx.jpg",
    title: "Machine Learning Blogs",
    paragraph:
      "AI: Time to change data into information for creating human intelligence.",
    link: "/machine_learning_blog",
  },
];
