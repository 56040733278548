import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import CardCarouselItem from "./CardCarouselItem";

class CardCarousel extends Component {
  state = {};
  render() {
    const carouselItem = [
      {
        imgsource:
          "http://res.cloudinary.com/datacode/image/upload/v1731504219/leryvzoaxtc5mvgxjs4j.jpg",
        title: "weekly Code Challange",
        link: "/index_code_challange",
      },
      {
        imgsource:
          "http://res.cloudinary.com/datacode/image/upload/v1731504689/wgrav3hwwf35myuehyhu.jpg",
        title: "Event and Conference",
        link: "/home",
      },
      {
        imgsource:
          "http://res.cloudinary.com/datacode/image/upload/v1731157170/znri1uoltjiuj5xxlqmr.jpg",
        title: "Community Web Blogs",
        link: "/webdesign_blog",
      },
      {
        imgsource:
          "http://res.cloudinary.com/datacode/image/upload/v1731505008/zvagupnxqkcewfgndw1j.jpg",
        title: "Technical Interview Questions",
        link: "/index_interview_questioins",
      },
      {
        imgsource:
          "http://res.cloudinary.com/datacode/image/upload/v1731505084/relqayy0bauchrvs6fag.jpg",
        title: "Python Programming",
        link: "/python",
      },
    ];

    const carouselItem2 = [
      {
        imgsource: "python_programming_question.jpeg",
        title: "Python Programming Questions",
        link: "/python_programming_questions",
      },
      {
        imgsource: "datastructure.jpeg",
        title: "Learn DataStructure from Beginner",
        link: "/datastructure",
      },
      {
        imgsource: "dbms_interview_questions.jpeg",
        title: "Top DBMS Interview Questions",
        link: "/dbms_interview_question",
      },
      {
        imgsource:
          "http://res.cloudinary.com/datacode/image/upload/v1731504219/leryvzoaxtc5mvgxjs4j.jpg",
        title: "weekly Code Challange",
        link: "/index_code_challange",
      },
      {
        imgsource: "comming_soon.jpeg",
        title: "coming soon",
        link: "/home",
      },
    ];

    return (
      <>
        {/* Desktop Carousel Cards  */}
        <div className='mb-5 d-none d-sm-block'>
          <Carousel indicators={false}>
            <Carousel.Item>
              <div className='card-deck'>
                {carouselItem.map((resource) => {
                  return (
                    <CardCarouselItem
                      key={resource.title}
                      imgsource={resource.imgsource}
                      title={resource.title}
                      link={resource.link}
                    />
                  );
                })}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='card-deck'>
                {carouselItem2.map((resource) => {
                  return (
                    <CardCarouselItem
                      key={resource.title}
                      imgsource={resource.imgsource}
                      title={resource.title}
                      link={resource.link}
                    />
                  );
                })}
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* Mobile Carousel Cards  */}
        <div className='mb-5 d-block d-md-none'>
          <Carousel indicators={false}>
            {[...carouselItem, ...carouselItem2].map((resource) => {
              return (
                <Carousel.Item key={resource.title}>
                  <CardCarouselItem
                    key={resource.title}
                    imgsource={resource.imgsource}
                    title={resource.title}
                    link={resource.link}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </>
    );
  }
}

export default CardCarousel;
