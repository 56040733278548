import React from "react";

import moment from "moment";
import { useEffect, useState } from "react";
import {
  FaCalendarAlt,
  FaCog,
  FaEye,
  FaHeart,
  FaRegHeart,
  FaShareAlt,
} from "react-icons/fa";

const BlogListCard = ({ lesson, items, selectedBLog }) => {
  const [formatDate, setFormatDate] = useState();
  const [isLiked, setIsLiked] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const date = moment(lesson?.updatedAt);
    setFormatDate(date.format("MMMM Do YYYY"));
    console.log(lesson, "lesson");
  }, [lesson]);
  return (
    <div>
      <div className='card h-100 blog-card shadow p-0'>
        <div className='blog-hover'>
          <img
            src={
              lesson.thumbnail ||
              "https://media.licdn.com/dms/image/v2/C5612AQEr3WmxhGSbxg/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1644862734630?e=2147483647&v=beta&t=M5AxxQvXksyGDUNYiIyZ7G0zAhfqdmYgrlQhMdTkLfI"
            }
            className='card-img-top'
            alt={"alt"}
          />
          <div className='card-body px-2 pt-2 '>
            <div className='d-flex justify-content-between align-items-center mb-1'>
              <span className='badge bg-primary'>
                {lesson.category || "Development"}
              </span>
              <span className='text-muted d-flex align-items-center mr-2'>
                <FaEye className='mr-1' />
                {12000}
              </span>
            </div>
            <h5 className='card-title '>
              {lesson.name.length > 24
                ? lesson.name.slice(0, 23) + "..."
                : lesson.name || "Nobody Wants To Wor..."}
            </h5>
            <p className='card-text text-muted'>
              {lesson.description?.length > 0
                ? lesson.description.slice(0, 115) + "..."
                : "Lorem ipsum dolor sit amet consectetur modi illo vel, itaque praesentium eos nihil, minima inventore labore mol..."}
            </p>
          </div>
          <div className='py-0 px-2 bg-transparent border-top-0'>
            <div className='d-flex justify-content-between align-items-center'>
              <span className='text-muted d-flex align-items-center'>
                {/* <FaUser className='mr-1' /> */}
                <img
                  className='j border mr-2 rounded-pill'
                  height={30}
                  width={30}
                  src={
                    lesson.author?.imgUrl ||
                    "https://miro.medium.com/v2/resize:fill:20:20/1*abnkL8PKTea5iO2Cm5H-Zg.png"
                  }
                  alt=''
                />
                {lesson.author
                  ? lesson.author?.firstName + " " + lesson.author?.lastName
                  : "Divy Attarde"}
              </span>
              {/* <small className='text-muted d-flex align-items-center'>
              <FaCalendarAlt className='mr-1' />
              {new Date(lesson?.date).toLocaleDateString()}
              {formatDate}
              </small> */}
            </div>
          </div>
          <hr className='my-2' />
          <div className='d-flex justify-content-between align-items-center card--footer'>
            <div className='d-flex gap-3 px-2'>
              <button
                className={`btn btn-link p-0 ${
                  isLiked ? "text-danger" : "text-muted"
                }`}
                // onClick={() => onLike(blog.id)}
              >
                {isLiked ? <FaHeart /> : <FaRegHeart />}
                <span className='ml-1'>450</span>
              </button>
              <button
                className='btn  p-0 text-muted'
                // onClick={handleShare}
              >
                <FaShareAlt />
              </button>
            </div>
            <div>
              <button
                className='btn btn-link py-0 px-3 text-muted'
                // onClick={handleShare}
              >
                <FaCog />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogListCard;
