import React, { useEffect } from "react";
import AOS from "aos";

const registrationData = [
  {
    step: "1",
    head: "Register",
    description: "Fill out the form to show your interest.",
  },
  {
    step: "2",
    head: "Quiz Test",
    description: "Showcase your problem-solving skills through an online quiz.",
  },
  {
    step: "3",
    head: "Programming Assesment",
    description: "Submit assesment on the provided template.",
  },
  {
    step: "4",
    head: "Interview",
    description: "Show off your passion in a quick discussion with our team.",
  },
];

const Registration = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="row mx-0 justify-content-center mt-4">
      <div className="col-11 col-md-10 col-lg-10 px-0 registration py-4">
        <h1 className="text-center pb-4">
          How to Join Our <span>Internship Program</span>
        </h1>
        <h5 className="pb-4 text-center">
          Follow these <span>4 simple</span> steps to start your journey:
        </h5>
        <div className="row mx-0">
          <div className="col-12 col-md-6 col-lg-6 px-0">
            <div className="row mx-0 mt-4">
              {registrationData.map((items) => (
                <div
                  className="col-12 pb-3"
                  data-aos="slide-left"
                  data-aos-delay="100"
                >
                  <div className="border py-2 px-3 d-flex boxx1 align-items-center box-shadow-team">
                    <div>
                      <div className="rounded-circle px-3 py-2 mb-0 number">
                        {items.step}
                      </div>
                    </div>
                    <div className="pl-3 d-flex align-items-center">
                      <div>
                        <h5 className="pt-2 text-light">{items.head}</h5>
                        <p className="para text-light">{items.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="col-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center"
            data-aos="slide-left"
            data-aos-delay="400"
          >
            <div>
              <img
                src={`http://res.cloudinary.com/datacode/image/upload/v1731935159/iaedfmlfbct2pmsz4sa9.jpg`}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Registration;
