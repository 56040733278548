import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { handleTextVisibility } from "../../../../../../utils";

const TutorialCard = ({
  id,
  title,
  likeTutorial,
  unlikeTutorial,
  likesCount,
  userData,
  userData: { _id, userName, email, myTutorialsLibrary },
  saveToLibrary,
  unsaveFromLibrary,
  tutorial,
  tutorial: { img, description, link, tags },
  handleTutorialList,
}) => {
  const history = useHistory();
  const [isLiked, setLiked] = useState();
  const [isSaved, setSaved] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setLiked(
      tutorial
        ? tutorial.likes &&
        tutorial.likes.find((like) => like === _id || like === userName)
        : false,
    );
    setLikeCount(tutorial ? tutorial.likes && tutorial.likes.length : 0);
    setSaved(
      userData &&
      userData.myTutorialsLibrary &&
      myTutorialsLibrary.find((lib) => lib === tutorial.id),
    );
  }, [tutorial, userData]);

  const handleUnlike = () => {
    if (!userName) {
      return history.push(`/login`);
    }

    unlikeTutorial({ id, user: _id }).then((res) => {
      if (res) {
        console.log(res);
        setLiked(
          res &&
          res?.data?.likes.find((like) => like === _id || like === userName),
        );
        setLikeCount(res && res?.data?.likes.length);
        // handleTutorialList()
      }
    });
  };

  const handleLike = () => {
    // @todo => if user is not logged in please do not allow to like or save in library
    // either show him/her popup to login or redirect or lgin page

    if (!userName) {
      return history.push(`/login`);
    } else {
      likeTutorial({ id, user: _id }).then((res) => {
        if (res) {
          console.log(res);
          setLiked(
            res?.data?.likes.find((like) => like === _id || like === userName),
          );
          setLikeCount(res?.data?.likes.length);
          // handleTutorialList()
        }
      });
    }
  };

  const handleSaveToLibrary = () => {
    if (!userName) {
      return history.push(`/login`);
    }
    const saveItem = tutorial.id;
    saveToLibrary({ saveItem, email }).then((res) => {
      if (res) {
        setSaved(true);
      }
    });
  };

  const handleUnsaveFromLibrary = () => {
    const saveItem = tutorial.id;
    unsaveFromLibrary({ saveItem, email }).then((res) => {
      if (res) {
        setSaved(false);
      }
    });
  };

  return (
    <>
      <div className='p-0 tutorial-card  mt-md-3 mt-4 border d-none d-md-block w-100'>
        <div className='row mx-0'>
          <div className='col-4 px-0 card-image'>
            <Link to={`/tutorial/${id}`}>
              <img
                src={
                  img
                    ? img
                    : require(`../../../../../../assets/images/svg/tech_mentor.svg`)
                }
                alt='avatar'
                loading='lazy'
                className='mx-auto my-auto text-center  '
                // crossOrigin="anonymous"
                sizes='(min-width: 1122px) 296px,(min-width: 866px) 218px,(max-width: 610px) 202px,202px'
              />
              {/* <img
            height="150px"
            width="150px"
            src={tutorial.img ? tutorial.img : require(`../../../../../../assets/images/svg/Python.png`)}
            alt="avatar"
            // className="card-img-top mx-auto img-fluid img-circle d-block"
          /> */}
            </Link>
          </div>

          <div className='col-8 py-3'>
            <div className='px-2'>
              <Link to={`/tutorial/${id}`}>
                <h6>{`${handleTextVisibility(title, 50)}`}</h6>
              </Link>
              <div className='tags-input row m-0 '>
                <div className='col-12 p-0'>
                  <div className='tags-area border-0'>
                    {tags.map((tag, index) => (
                      <div className='tag ml-0' key={index}>
                        <span className='mb-0'>{tag}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <p className=''>{`${handleTextVisibility(description, 100)}`}</p>
            </div>
            <div className='row m-0 px-2 tech-card-footer'>
              <div className='col-12 p-0  align-items-center'>
                <span className='green font-weight-bold mb-1'>{likeCount}</span>
                {isLiked ? (
                  <i
                    onClick={() => handleUnlike()}
                    className='fas fa-heart red mx-2'
                  />
                ) : (
                  <i
                    onClick={() => handleLike()}
                    className='far fa-heart red mx-2'
                  />
                )}
                <Link to={`/tutorial/${id}`}>
                  <i className='fad fa-comments-alt voilet mx-2' />
                </Link>
                {isSaved ? (
                  <i
                    onClick={() => handleUnsaveFromLibrary()}
                    className='fas fa-bookmark voilet mx-2'
                  />
                ) : (
                  <i
                    onClick={() => handleSaveToLibrary()}
                    className='far fa-bookmark voilet mx-2'
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-100 p-0 tutorial-card mx-0 mx-md-5 mt-md-3 mt-4 d-block d-md-none border'>
        <div className='row mx-0'>
          <div className='col-12 px-0 card-image'>
            <Link to={`/tutorial/${id}`}>
              <img
                src={
                  img
                    ? img
                    : require(`../../../../../../assets/images/svg/tech_mentor.svg`)
                }
                alt='avatar'
                loading='lazy'
                className='mx-auto my-auto text-center'
                // crossOrigin="anonymous"
                sizes='(min-width: 1122px) 296px,(min-width: 866px) 218px,(max-width: 610px) 202px,202px'
              />
              {/* <img
            height="150px"
            width="150px"
            src={tutorial.img ? tutorial.img : require(`../../../../../../assets/images/svg/Python.png`)}
            alt="avatar"
          // className="card-img-top mx-auto img-fluid img-circle d-block"
          /> */}
            </Link>
          </div>
        </div>

        <div className='tags-input row m-0'>
          <div className='col-12 p-0'>
            <div className='tags-area border-0'>
              {tags.map((tag, index) => (
                <div className='tag align-item-center d-flex' key={index}>
                  <span className='mb-0'>{tag}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='px-2'>
          <Link to={`/tutorial/${id}`}>
            <h6>{`${handleTextVisibility(title, 40)}`}</h6>
          </Link>
          <p className='pb-3'>{`${handleTextVisibility(description, 100)}`}</p>
        </div>
        <div className='row m-0 mb-2 text-right p-0 tech-card-footer'>
          <div className='col-12 p-0 text-right align-items-center'>
            <span className='green font-weight-bold mb-1'>{likeCount}</span>
            {isLiked ? (
              <i
                onClick={() => handleUnlike()}
                className='fas fa-heart red mx-2'
              />
            ) : (
              <i
                onClick={() => handleLike()}
                className='far fa-heart red mx-2'
              />
            )}
            <Link to={`/tutorial/${id}`}>
              <i className='fad fa-comments-alt voilet mx-2' />
            </Link>
            {isSaved ? (
              <i
                onClick={() => handleUnsaveFromLibrary()}
                className='fas fa-bookmark voilet mx-2'
              />
            ) : (
              <i
                onClick={() => handleSaveToLibrary()}
                className='far fa-bookmark voilet mx-2'
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

TutorialCard.defaultProps = {
  userName: "",
  email: "",
  id: "",
  likesCount: 0,
  userData: {},
  tutorial: {},
  myTutorialsLibrary: [],
  tags: [],
};

TutorialCard.propTypes = {
  userName: PropTypes.string,
  id: PropTypes.string,
  likesCount: PropTypes.number,
  userData: PropTypes.object,
  tutorial: PropTypes.object,
  myTutorialsLibrary: PropTypes.array,
};

export default TutorialCard;
