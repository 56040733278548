import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faFlask } from "@fortawesome/free-solid-svg-icons";
import RightSideSection from "../../../../right_section/RightSideSection";
import MCQTestCardCarousel from "./MCQTestCardCarousel";
import PostCard from "../../../PostCard";
import Technologies from "../../../../right_section/Technologies";
import { Helmet } from "react-helmet";
import { quizTests } from "./helper";
class IndexQuizeTests extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const description =
      "Time to boost your Brain, let’s evaluate our skills without being fear of judged.";
    const title = "Self Evalution Programming Quiz Tests | Datacode.in";
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta
            data-react-helmet='true'
            name='description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            http-equiv='cleartype'
            content='on'
          ></meta>
          <meta
            data-react-helmet='true'
            name='apple-mobile-web-app-capable'
            content='yes'
          ></meta>
          <meta
            data-react-helmet='true'
            name='robots'
            content='max-snippet:-1, max-image-preview:large, max-video-preview:-1'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:locale'
            content='en_US'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:type'
            content='website'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:title'
            content={title}
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:url'
            content='https://www.datacode.in/'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:site_name'
            content='Datacode.in'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:secure_url'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:width'
            content='400'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:height'
            content='400'
          ></meta>
          <meta
            data-react-helmet='true'
            name='keywords'
            content='programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:card'
            content='summary_large_image'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:title'
            content={title}
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:site'
            content='@datacode_in'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:creator'
            content='@datacode_in'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:image'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <link
            data-react-helmet='true'
            rel='shortlink'
            href='https://www.datacodoe.in/'
          ></link>
          <link
            data-react-helmet='true'
            rel='dns-prefetch'
            href='//www.datacode.in'
          ></link>
          <link
            data-react-helmet='true'
            rel='dns-prefetch'
            href='//s.w.org'
          ></link>
          <link
            data-react-helmet='true'
            rel='alternate'
            type='application/rss+xml'
            title='Datacode'
            href='https://www.datacode.in/'
          ></link>
          <link
            data-react-helmet='true'
            rel='canonical'
            href='https://www.datacode.in/'
          ></link>
        </Helmet>
        <div className='row m-0 px-3'>
          <div className='col-1'></div>
          <div className='col-md-8 mt-5'>
            <h1
              className='text-center mb-4 rounded p-2'
              style={{ backgroundColor: "#30006b", color: "#ffffff" }}
            >
              <FontAwesomeIcon icon={faFlask} /> Programming Quiz Test
            </h1>
            <h5 className='text-center my-3'>
              Time to boost your Brain, let’s evaluate our skills without being
              fear of judged.
            </h5>
            <p className='text-center'>
              Learning never exhausts the mind, it’s time to become a gamer in
              the world of programming. Hustling should not be stopped!{" "}
            </p>
            <p className='text-center mb-5'>
              Doing Practice makes you perfect, let’s dive in the era of
              programming.
            </p>

            <Link
              to='/home'
              className='badge mr-1 my-4'
              style={{ backgroundColor: "#06CB6C" }}
            >
              <FontAwesomeIcon icon={faHome} size='2x' color='#072C71' />
            </Link>

            {/* <div className='card-deck mb-5'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169083/ksv25dx4glbemsmu9u8z.jpg'
                title='C Programming Quiz Test'
                paragraph=''
                link='/c_quize_tests'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169103/tjhl4coyvtlbftcsnaac.jpg'
                title='Python Programming Quiz Test'
                paragraph=''
                link='/python_quize_tests'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169203/fq5r4sobilllurzr3nwv.jpg'
                title='Java Programming Quiz Test'
                paragraph=''
                link='/java_quize_tests'
              />
            </div>
            <div className='card-deck mb-5'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169232/spgqkqm3iqkctgwdyw7t.jpg'
                title='HTML & CSS Quiz Test'
                paragraph=''
                link='/html_quize_tests'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169265/juzusy8ftumuxxarihno.jpg'
                title='JavaScript Programming Quiz Test'
                paragraph=''
                link='/js_quize_tests'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169291/gbyj1woek3smjgoo7gcp.jpg'
                title='DataStructure Quiz Test'
                paragraph=''
                link='/ds_quize_tests'
              />
            </div>
            <div className='card-deck mb-5'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169316/v0arm9bmnugkyluivb27.jpg'
                title='DBMS Quiz Test'
                paragraph=''
                link='/dbms_quize_tests'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169344/vfbbsuytfcwdrjnaqhf1.jpg'
                title='SQL Quiz Test'
                paragraph=''
                link='/sql_quize_tests'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169369/scliwp8ssigcxpu9ufqn.jpg'
                title='OOPs Programming Quiz Test'
                paragraph=''
                link='/oops_quize_tests'
              />
            </div>
            <div className='card-deck mb-5'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169403/j9ejuquujnb76rmjp78z.jpg'
                title='Angular Quiz Test'
                paragraph=''
                link='/angular_quize_tests'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169433/xwb46qsei0d3vqcrol1t.jpg'
                title='React Quiz Test'
                paragraph=''
                link='/react_quize_tests'
              />
            </div> */}
            <div className='col-12 px-0'>
              <div className='row'>
                {quizTests.map((resource) => {
                  return (
                    <div className='col-md-4 my-3' key={resource.title}>
                      <PostCard
                        imgsource={resource.imgsource}
                        title={resource.title}
                        paragraph={resource.paragraph}
                        link={resource.link}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <h5 className='mt-3'>For More Quiz Tests</h5>
            <MCQTestCardCarousel />
          </div>

          <div className='col-md-3'>
            <RightSideSection />
            <Technologies />
          </div>
        </div>
      </>
    );
  }
}

export default IndexQuizeTests;
