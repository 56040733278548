import { faChalkboardTeacher, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import FbLikeShare from "../../../FbLikeShare";
import LearningSideSection from "../../../right_section/learning_sidesection/LearningSideSection";
import RightSideSection from "../../../right_section/RightSideSection";
import Technologies from "../../../right_section/Technologies";
import RoadMapCard from "../../tutorials/RoadMapCard";
import InterviewQuestionCardCarousel from "./InterviewQuestionCardCarousel";
import QuestionCard from "./QuestionCard";
import { interviewQuestions, otherLearningResource } from "./helper";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const TechnologyQuestions = () => {
  const location = useLocation();
  const currentLocation = location.pathname.slice(20);
  return (
    <>
      <div className='row mx-0'>
        <div className='col-1'></div>
        <div className='col-md-7 mt-4'>
          <h2
            className='text-center mb-4 display-5'
            style={{ color: "#072C71" }}
          >
            {`Find the Best ${currentLocation.toUpperCase()} Interview Questions`}
          </h2>
          <Link
            to='/home'
            className='badge mr-1'
            style={{ backgroundColor: "#06CB6C" }}
          >
            <FontAwesomeIcon icon={faHome} size='2x' color='#072C71' />
          </Link>
          <Link
            to='/index_interview_questioins'
            className='badge mr-1'
            style={{ backgroundColor: "#072C71" }}
          >
            <FontAwesomeIcon
              icon={faChalkboardTeacher}
              size='2x'
              color='#06CB6C'
            />
          </Link>
          <div className='row mb-5'>
            <div className='col'>
              {interviewQuestions
                .filter((tech) => tech.technology === currentLocation)
                .map((item, i) => {
                  return (
                    <div key={i}>
                      <QuestionCard
                        question={item.question}
                        answer={item.answer}
                      />
                    </div>
                  );
                })}
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <h4 className='text-primary'>For More Technologies</h4>
              <InterviewQuestionCardCarousel />
            </div>
          </div>
        </div>

        <div className='col-md-4 mt-5'>
          <FbLikeShare />{" "}
          <h4 className='mb-3 mt-4' style={{ color: "#072C71" }}>
            Other Learning Resources
          </h4>
          {otherLearningResource
            .filter((tech) => tech.technology === currentLocation)
            .map((item, i) => {
              return (
                <div key={i}>
                  <RoadMapCard
                    imgsource={item.imgsource}
                    title={item.title}
                    source={item.source}
                    tag1={item.tag1}
                    tag2={item.tag2}
                    link={item.link}
                  />
                </div>
              );
            })}
          <LearningSideSection language={currentLocation} />
          <RightSideSection />
          <Technologies />
        </div>
      </div>
    </>
  );
};

export default TechnologyQuestions;
